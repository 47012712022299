import React from "react";
import { Modal, Input, Form, Button, Layout, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { getLocaleMessages } from "redux/helper";

import "assets/css/login-signup.scss";

const { Content } = Layout;

const { Option } = Select;

const ForgotPasswordConfirmed = (props) => {
  const { onLoginForgot, onFinish, setPage, type } = props;

  return (
    <>
      <section className="login-dashboard">
        <div className="login-box">
          <NavLink to={"/"} className="back_to_page">
            <CloseOutlined />
          </NavLink>
          <div className="login-box-center">
            <div className="login-headers">
              <div>
                <NavLink to={"/"}>
                  <img
                    src={require("../../assets/img/logo-clolor.png")}
                    alt=""
                  />
                </NavLink>
              </div>

              <h3>
                {getLocaleMessages({
                  id: "placeholder.resetEmail",
                })}
              </h3>
            </div>

            <div className="text-right forgotLink">
              <div>
                <NavLink
                  to={type === "admin" ? "/admin/login" : "/auth?type=login"}
                >
                  <Button onClick={(e)=>{e.stopPropagation();setPage && setPage("")}}>
                    <span>Back to login</span>
                  </Button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPasswordConfirmed;
