import React, { useEffect, useState } from "react";
import { Layout, Spin, message } from "antd";
import AppRouter from "routes/AppRouter";
import Sidebar from "containers/Sidebar/Sidebar";
import VendorSidebar from "containers/Sidebar/VendorSidebar";
// import { history, store } from 'redux/store';
// import actions from 'redux/app/actions';
import Header from "containers/Layouts/Header";
import CustomerHeader from "containers/Layouts/CustomerHeader";
import { getLocalDataType } from "redux/helper";
import privateRoutes from "routes/privateRoutes";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const { Content } = Layout;

function App(props) {
  const { location } = props;
  const loginType = localStorage.getItem("login_type");
  const loginUserId = JSON.parse(localStorage.getItem("login_user_id"));
  const temUserId = JSON.parse(localStorage.getItem("user_data"));
  const pageLocation = useLocation();
  const { loader } = useSelector((state) => state.loaderReducer);

  function checkRoutesExtension() {
    if (
      (location.pathname.startsWith("/admin/") &&
        getLocalDataType() === "admin") ||
      (location.pathname.startsWith("/vendor/") &&
        getLocalDataType() === "vendor")
    ) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    const splitpath =
      pageLocation.pathname.replace(/^\/|\/$/g, "") !== ""
        ? pageLocation.pathname.replace(/^\/|\/$/g, "")
        : pageLocation.pathname;

    if (
      loginType === "vendor" &&
      privateRoutes["common"].filter((r) => r.path === splitpath).length > 0
    ) {
      pageLocation.pathname = "/vendor/dashboard";
    }
  }, [localStorage, pageLocation.pathname]);

  return (
    <Layout>
      {getLocalDataType() === "user" ? <CustomerHeader /> : <Header />}
      <Spin size="large" spinning={checkRoutesExtension() ? loader : false}>
        <Layout>
          {location.pathname.startsWith("/admin/") &&
            getLocalDataType() === "admin" && <Sidebar />}
          {location.pathname.startsWith("/vendor/") &&
            getLocalDataType() === "vendor" && <VendorSidebar />}
          <Content>
            <div className={"layout-content"}>
              <AppRouter />
            </div>
          </Content>
        </Layout>
      </Spin>
    </Layout>
  );
}

export default App;
