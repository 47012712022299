import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  Layout,
  Menu,
  Steps,
  Avatar,
  Typography,
  Dropdown,
  Button,
  Badge,
  message,
  notification,
} from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'redux/auth/actions';
import adminvendorprofileAction from 'redux/admin/adminvendorprofile/actions';
import Autocomplete from 'react-google-autocomplete';
import {
  ExclamationCircleOutlined,
  UserOutlined,
  CloseCircleTwoTone,
  BellOutlined,
} from '@ant-design/icons';
import SignupModal from 'containers/Layouts/SignupModal';
import SigninModal from 'containers/Layouts/SigninModal';
import ForgotModal from 'containers/Layouts/ForgotModal';
import OTP from 'containers/Layouts/OTP';
import 'assets/css/header.scss';
import 'assets/css/modal.scss';
import 'assets/css/responsive.scss';
import { getLocalDataType, getLocalData } from 'redux/helper';
import { history, store } from 'redux/store';
import layoutActions from 'redux/Layout/actions';
import ListingLayoutAction from 'redux/ListingLayout/actions';
import serviceActions from 'redux/vendor/Services/actions';
import seractions from 'redux/vendor/Services/actions';
import { useHistory } from 'react-router-dom';

const { Text } = Typography;

const Header = () => {
  const formatted_address = localStorage.getItem('formatted_address');
  const latRef = useRef();
  const autoCompleteInputRef = useRef();
  const lngRef = useRef();
  const {
    isserviceaccepted,
    isstaffaccepted,
    isprofileaccepted,
    notificationData,
  } = useSelector((state) => state.AdminVendorProfile);
  const { staffList } = useSelector((state) => state.Services);
  const { categoryServiceByVendorList } = useSelector(
    (state) => state.Services
  );
  const LayoutListing = useSelector((state) => state.ListingLayout);

  const {
    /* initialListingLoader,
    listingSaloonLoader,
    listingSaloonData,
    categorysaloonDetail,*/
    locationName,
    //vendorbynameData,
  } = LayoutListing;
  const [getLatitude, setLatitude] = useState(0);
  const [getLongitude, setLongitude] = useState(0);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [selectedLocation, setselectedLocation] = useState('');
  const [cityName, setCityName] = useState('');
  const [notificationlist, setnotificationlist] = useState([]);
  const [notificationcount, setnotificationcount] = useState(0);
  const [forMatAdd, setForMatAdd] = useState(null);
  const [AutoAddress, setAutoAddress] = useState(
    formatted_address
      ? formatted_address
      : locationName !== ''
      ? locationName
      : 'Saudi Arabia'
  );
  const pageHistory = useHistory();
  const AutoCompleteFunction = () => {
    return (
      <Autocomplete
        className='custom-autocomplete'
        apiKey={'AIzaSyD2fIwEEQ7r4n9OSVvOBMblCVCxfz23aro'}
        onPlaceSelected={(place) => {
          searchLocation(place);
        }}
        defaultValue={formatted_address}
      />
    );
  };

  useEffect(() => {
    setAutoAddress(
      formatted_address !== null ||
        formatted_address !== undefined ||
        formatted_address !== ''
        ? formatted_address
        : forMatAdd !== null
        ? forMatAdd
        : 'Saudi Arabia'
    );
  }, [formatted_address, locationName]);

  // drawer
  const currentPageLocation = useLocation();
  const {
    //subLang,
    isLoggedIn,
    isOtp,
    isemail,
    getAppLanguageList,
    //selectedLanguage,
    //languageLoader,
  } = useSelector((state) => state.Auth);
  const { loading } = useSelector((state) => state.App);
  const dispatch = useDispatch();

  //modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLVisible, setIsModalLVisible] = useState(false);
  const [isForgotVisible, setIsForgotVisible] = useState(false);
  const [isOtpVisible, SetIsOtpVisible] = useState(false);
  const [OTPdata, setOTPdata] = useState('');
  const [orderStep, setOrderStep] = useState(null);

  const option = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const success = ({ coords }) => {
    setLatitude(coords.latitude);
    setLongitude(coords.longitude);
    setCurrentLocation(coords);
  };

  function routeToNotificationPage() {
    window.location.href = '/vendor/notification';
  }

  const changeadminnotifiaction = (usertype) => {
    if (usertype == 'admin') {
      dispatch({
        type: adminvendorprofileAction.CLEAR_ALL_NOTIFICATION,
        payload: {
          userid: 1,
          usertype: 'administration',
        },
        callBackAction: (status) => {
          if (status) {
            setnotificationcount(0);
          }
        },
      });
    }
    if (usertype == 'vendor') {
      dispatch({
        type: adminvendorprofileAction.CLEAR_ALL_NOTIFICATION,
        payload: {
          userid: getLocalData('id'),
          usertype: 'vendor',
        },
        callBackAction: (status) => {
          if (status) {
            setnotificationcount(0);
          }
        },
      });
    }
  };

  const handleNotificationReadMarked = (id) => {
    dispatch({
      type: adminvendorprofileAction.SET_NOTIFICATION_MARKED,
      payload: {
        id: id,
        userid: getLocalDataType() == 'admin' ? 1 : getLocalData('id'),
        usertype: getLocalDataType() == 'admin' ? 'administration' : 'vendor',
      },
      callBackAction: (status) => {},
    });
  };
  const [clicked, setClicked] = useState(false);

  const hide = () => {
    setClicked(false);
  };
  const showModal = ({ signupModalVisible }) => {
    setIsModalVisible(signupModalVisible);
  };

  const showLModal = ({ loginModalVisible }) => {
    setIsModalLVisible(loginModalVisible);
  };

  const searchLocation = (place) => {
    if (place.name === undefined) {
      const cityName = place.address_components[0].long_name;
      setCityName(cityName);

      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      //setSaloonList()
      setCurrentLocation({
        latitude: latitude,
        longitude: longitude,
      });
      setLatitude(latitude);
      setLongitude(longitude);
      setselectedLocation(place.formatted_address);
      localStorage.setItem('formatted_address', place.formatted_address);
      localStorage.setItem('cityName', cityName);
      localStorage.setItem('location', { lat: latitude, lng: longitude });
    }
  };

  const fOk = () => {
    setIsForgotVisible(false);
  };

  const LoginForgot = ({ login, forgot }) => {
    if (forgot) {
      setIsModalLVisible(login);
      setIsForgotVisible(forgot);
    } else if (login) {
      setIsForgotVisible(forgot);
      setIsModalLVisible(login);
    }
  };

  const LoginSignup = ({ login, signup }) => {
    if (login) {
      setIsModalVisible(signup);
      setIsModalLVisible(login);
    } else if (signup) {
      setIsModalLVisible(login);
      setIsModalVisible(signup);
    }
  };

  const otpOk = () => {
    Modal.confirm({
      title: 'Warning',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to close this modal and the otp modal never open',
      okText: 'ok',
      cancelText: 'cancel',
      maskClosable: true,
      mask: true,
      onOk: () => {
        dispatch({
          type: actions.VERIFY_OTP_SUCCESS,
        });
      },
    });
  };

  const onFinish = (values) => {
    values = {
      ...values,
      ...values['mobile_number'],
      confirmpassword: values['password'],
      cityid: 1,
      countryid: 1,
    };
  };

  const onFinishLogin = (values) => {
    dispatch({
      type: actions.AUTHENTICATE_USER,
      payload: values,
      callBackAction: () => {
        showLModal({ loginModalVisible: false });
      },
    });
  };

  const onFinishForgot = (values) => {
    dispatch({
      type: actions.SEND_PASSWORD_RESET_LINK,
      payload: values,
      callBackAction: () => {
        LoginForgot({
          login: true,
          forgot: false,
        });
      },
    });
  };

  const onFinishOTP = () => {
    if (OTPdata.length === 5) {
      dispatch({
        type: actions.VERIFY_OTP,
        payload: {
          otp: OTPdata,
          email: isemail,
        },
        callBackAction: (status) => {
          if (status) {
            SetIsOtpVisible(false);
            setOTPdata('');
          }
        },
      });
    } else {
      message.error('please enter valid OTP');
    }
  };
  // function handleChange(value) {
  //   if (value === "all") {
  //     store.dispatch({
  //       type: ListingLayoutAction.GET_CATEGORY_SALOON_DETAILS,
  //     });
  //   } else {
  //     store.dispatch({
  //       type: ListingLayoutAction.GET_CATEGORY_SALOON_DETAILS,
  //       payload: value,
  //       userid: getLocalData("id"),
  //     });
  //     setfiltercategoryid(value);
  //     // aa
  //   }
  // }
  // function handleHotelSearch(val) {
  //   store.dispatch({
  //     type: ListingLayoutAction.GET_VENDOR_BY_NAME,
  //     payload: val,
  //   });
  // }
  const onChangeOTP = (value) => {
    setOTPdata(value);
  };

  const handleChangeLanguage = (value) => {
    let newListLanguage = getAppLanguageList.filter(
      (list) => list.id === parseInt(value)
    );

    localStorage.removeItem('site_language');
    localStorage.removeItem('site_language_full');
    //localStorage.setItem("site_language_full", "english");
    //localStorage.setItem('language_id', 1)
    localStorage.setItem(
      'site_language',
      newListLanguage[0]['languageshortname']
    );
    localStorage.setItem('language_id', newListLanguage[0]['id']);
    localStorage.setItem(
      'site_language_full',
      newListLanguage[0]['languagename']
    );
    dispatch({
      type: actions.SET_SELECTED_LANGUAGE,
      payload: newListLanguage[0],
    });
    store.dispatch({
      type: actions.CHANGE_LANGUAGE,
      payload: newListLanguage[0]['languageshortname'],
    });
  };

  const handleVisibleDraw = () => {
    const inputSelect = autoCompleteInputRef.current.querySelectorAll('input');
    inputSelect.forEach((singleInput) => (singleInput.value = ''));
    localStorage.setItem('formatted_address', '');
    message.info('Enter a location to search');
  };

  const loginMenu = (
    <Menu>
      <Menu.Item
        key='logout'
        onClick={() => {
          dispatch({
            type: actions.LOGOUT_USER,
          });
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  const adminnotificationMenu = (
    <Menu style={{ overflow: 'auto' }}>
      {notificationlist.length > 0 ? (
        notificationlist.map((data) => {
          return (
            <Menu.Item
              key={data.id}
              onClick={() => handleNotificationReadMarked(data.id)}
            >
              <a href={`/admin/Notificationview/${data.id}`}>
                {data.notification_content.en && data.notification_content.en}
              </a>
            </Menu.Item>
          );
        })
      ) : (
        <div style={{ padding: '5px' }}>Nothing found</div>
      )}
    </Menu>
  );

  const vendornotificationMenu =
    notificationlist.length > 0 ? (
      <Menu style={{ overflow: 'auto' }}>
        {notificationlist.length &&
          notificationlist.slice(0, 4).map((data) => {
            return (
              <Menu.Item
                key={data.id}
                onClick={() => {
                  console.log('test');
                }}
              >
                <a href={`/vendor/ViewNotification/${data.id}`}>
                  {data.notification_content.en && data.notification_content.en}
                </a>
              </Menu.Item>
            );
          })}
        <div className='see_more_style' onClick={routeToNotificationPage}>
          {notificationlist.length > 0 ? 'see more' : ''}
        </div>
      </Menu>
    ) : (
      <Menu style={{ overflow: 'auto', padding: '10px' }}>Nothing found</Menu>
    );

  useEffect(() => {
    if (currentLocation !== null) {
      dispatch({
        type: ListingLayoutAction.SET_CURRENT_LATLONG,
        payload: [
          {
            latitude: currentLocation?.latitude,
            longitude: currentLocation?.longitude,
          },
        ],
      });
    }
  }, [currentLocation]);

  useEffect(() => {
    if (notificationData.length) {
      setnotificationlist(notificationData[0]);
      let countArr = notificationData[0].filter((item, i) => {
        return item.isviewed != 1;
      });
      setnotificationcount(countArr.length);
    }
  }, [notificationData]);

  useEffect(() => {
    if (isOtpVisible) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [isOtpVisible]);

  useEffect(() => {
    store.dispatch({
      type: layoutActions.GET_LAYOUT_CATEGORIES,
    });
    if (isOtp) {
      setIsModalVisible(false);
      SetIsOtpVisible(isOtp);
    } else {
      SetIsOtpVisible(false);
      setOTPdata('');
    }
  }, [isOtp]);

  useEffect(() => {
    if (currentLocation !== null) return;
    const data = {};
    data['latitude'] = getLatitude;
    data['longitude'] = getLongitude;
    if (cityName) {
      data['vendoraddress'] = cityName;
    }
    let urlParams = new URLSearchParams(history.location.search);
    if (urlParams.get('categoryid')) {
      data['categoryid'] = parseInt(urlParams.get('categoryid'));
    }
  }, [selectedLocation, currentLocation]);

  useEffect(() => {
    if (getLocalDataType() === 'vendor') {
      dispatch({
        type: adminvendorprofileAction.GET_SINGLE_VENDOR,
        value: getLocalData('id'),
      });
      dispatch({
        type: serviceActions.GET_VENDORSTAFF_LIST,
        id: getLocalData('id'),
        callBackAction: (response) => {},
      });
      dispatch({
        type: seractions.GET_CATEGORY_SERVICE_By_VENDOR_LIST,
        vendorid: getLocalData('id'),
        languageid: 1,
        callBackAction: (response) => {},
      });
    }
  }, []);

  useEffect(() => {
    if (getLocalDataType() === 'admin') {
      dispatch({
        type: adminvendorprofileAction.GET_ALL_NOTIFICATION,
        payload: {
          userid: 1,
          usertype: 'administration',
        },
      });
    }
    if (getLocalDataType() === 'vendor') {
      dispatch({
        type: adminvendorprofileAction.GET_ALL_NOTIFICATION,
        payload: {
          userid: getLocalData('id'),
          usertype: 'vendor',
        },
      });
    }
    if (getLocalDataType() === 'user') {
      dispatch({
        type: adminvendorprofileAction.GET_ALL_NOTIFICATION,
        payload: {
          userid: getLocalData('id'),
          usertype: 'user',
        },
      });
    }
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, console.warn, option);
  }, []);

  const openNotification = (type) => {
    notification.warning({
      key: type,
      message: 'Warning',
      description:
        type === 'profile'
          ? 'Please Update Profile'
          : type === 'services'
          ? 'Please Create Menu'
          : 'Please Create Staff / Staff time',
      placement: 'bottom',
    });
  };

  useEffect(() => {
    if (orderStep === null) {
      if (
        isLoggedIn &&
        getLocalDataType() === 'vendor' &&
        isprofileaccepted == 0
      ) {
        pageHistory.push('/vendor/profile');
        openNotification('profile');
      }
      if (
        isLoggedIn &&
        getLocalDataType() === 'vendor' &&
        isprofileaccepted == 1 &&
        isserviceaccepted == 0
      ) {
        currentPageLocation.pathname = '/vendor/services';
        pageHistory.push('/vendor/services');
        openNotification('services');
      }

      if (
        isLoggedIn &&
        getLocalDataType() === 'vendor' &&
        isprofileaccepted == 1 &&
        isstaffaccepted == 0 &&
        isserviceaccepted == 1
      ) {
        pageHistory.push('/vendor/staff');
        openNotification('staff');
      }
    }
  }, [
    staffList,
    categoryServiceByVendorList,
    isprofileaccepted,
    isstaffaccepted,
    isserviceaccepted,
  ]);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function success({ coords }) {
        // console.log(coords, "pageInitialValue");
        latRef.current = coords.latitude;
        lngRef.current = coords.longitude;
        if (coords.latitude !== undefined && coords.longitude !== undefined) {
          dispatch({
            type: ListingLayoutAction.GET_CURRENT_ADDRESS_STRING,
            payload: {
              lat: coords.latitude,
              lng: coords.longitude,
              key: 'AIzaSyD2fIwEEQ7r4n9OSVvOBMblCVCxfz23aro',
            },
            callBackAction: (res) => {
              setForMatAdd(res.data.results[0].formatted_address);
              localStorage.setItem(
                'formatted_address',
                res.data.results[0].formatted_address
              );
            },
          });
        }
      },
      console.warn,
      option
    );
  }, [latRef, lngRef, currentPageLocation.pathname]);

  const onChange = (value) => {
    if (value === 0) {
      setOrderStep(0);
      pageHistory.push('/vendor/profile');
    } else if (value === 1) {
      setOrderStep(1);
      pageHistory.push('/vendor/services');
    } else if (value === 2) {
      setOrderStep(2);
      pageHistory.push('/vendor/staff');
    }
  };

  const currentStep = () => {
    if (isprofileaccepted === 0) {
      return 0;
    } else if (isprofileaccepted == 1 && isserviceaccepted == 0) {
      return 1;
    } else if (
      isprofileaccepted == 1 &&
      isserviceaccepted == 1 &&
      isstaffaccepted === 0
    ) {
      return 2;
    }
  };
  return (
    <>
      <Layout.Header
        className={isLoggedIn ? 'site-header site-header-admin' : 'site-header'}
      >
        <div className='container'>
          <div className='logo'>
            {' '}
            <NavLink
              to={{
                pathname: ['admin', 'vendor'].includes(getLocalDataType())
                  ? `/${getLocalDataType()}/dashboard`
                  : '/',
              }}
            >
              <img
                src={require('../../assets/img/logo-clolor.png')}
                alt=''
                className='img-fluid'
              />
            </NavLink>
          </div>
          {(currentPageLocation.pathname === '/listing' ||
            currentPageLocation.pathname === '/detail') && (
            <div className='search-and-location'>
              <div className='search_box' ref={autoCompleteInputRef}>
                {AutoCompleteFunction()}
                <span className='location' onClick={handleVisibleDraw}>
                  <h3
                    style={{
                      textDecoration: 'none',
                      borderRadius: '50%',
                      backgroundColor: '#f9a392',
                      color: 'white',
                      padding: ' 5px 10px',
                      margin: '0px 5px',
                      fontSize: '14px',
                    }}
                  >
                    x
                  </h3>
                </span>
              </div>
            </div>
          )}

          {isLoggedIn && getLocalDataType() === 'vendor' && (
            <div style={{ width: '60%' }}>
              <Steps
                current={orderStep !== null ? orderStep : currentStep()}
                onChange={onChange}
                // status={}
                items={[
                  {
                    title: 'Update Profile',
                    description: isprofileaccepted === 0 &&
                      (currentStep() === 0 || orderStep === 0) && (
                        <p style={{ fontSize: '0.6rem' }}>
                          Please Update Profile
                        </p>
                      ),
                    ...(isprofileaccepted === 0 && {
                      icon: <CloseCircleTwoTone twoToneColor='#52c41a' />,
                    }),
                    ...(isprofileaccepted === 1 && { status: 'finish' }),
                  },
                  {
                    title: 'Create menu',
                    description: isserviceaccepted === 0 &&
                      (currentStep() === 1 || orderStep === 1) && (
                        <p style={{ fontSize: '0.6rem' }}>Please Create Menu</p>
                      ),
                    disabled: isprofileaccepted === 0,
                    ...(isprofileaccepted === 0 && {
                      icon: <CloseCircleTwoTone twoToneColor='#52c41a' />,
                    }),
                    ...(isserviceaccepted === 1 && { status: 'finish' }),
                  },
                  {
                    title: 'Create Staff / Staff time',
                    description: isstaffaccepted === 0 &&
                      (currentStep() === 2 || orderStep === 2) && (
                        <p style={{ fontSize: '0.6rem' }}>
                          {' '}
                          Please Create Staff / Time
                        </p>
                      ),
                    disabled: isserviceaccepted === 0, 
                    ...(isserviceaccepted === 0 && {
                      icon: <CloseCircleTwoTone twoToneColor='#52c41a' />,
                    }),
                    ...(isstaffaccepted === 1 && { status: 'finish' }),
                  },
                ]}
              />
            </div>
          )}

          <div className='menu-navigation'>
            <ul>
              {/*
              <Select
                className="language-drop"
                value={selectedLanguage["id"]}
                onChange={handleChangeLanguage}
                loading={languageLoader}
                disabled={languageLoader}
              >
                {getAppLanguageList.map((list, index) => (
                  <Option value={list["id"]} key={index}>
                    {list["languagename"]}
                  </Option>
                ))}
              </Select>*/}
              {!isLoggedIn && (
                <>
                  <li>
                    <NavLink to={'/vendor/login'}>For Partners</NavLink>
                  </li>
                  <li>
                    <NavLink to={'/auth?type=signup'}>Sign up</NavLink>
                  </li>
                  <li className='login_a'>
                    <NavLink to={'/auth?type=login'}>Log in</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to={"/vendor/login"}>For Partners</NavLink>
                  </li>
                  <li>
                    <NavLink to={"/auth?type=signup"}>Sign up</NavLink>
                  </li>
                  <li className="login_a">
                    <a onClick={() => showLModal({ loginModalVisible: true })}>
                      Log in
                    </a>
                  </li> */}
                </>
              )}
              {/* <li>
                <Button
                  type="dashed"
                  onClick={
                    selectedLanguage["id"] == 2
                      ? () => handleChangeLanguage(1)
                      : () => handleChangeLanguage(2)
                  }
                >
                  {localStorage.getItem("site_language_full") == "English"
                    ? "Arabic"
                    : "English"}
                </Button>
              </li>*/}

              {isLoggedIn && getLocalDataType() === 'vendor' && (
                <li>
                  <Dropdown
                    overlay={vendornotificationMenu}
                    overlayClassName='admin_notifiction'
                    placement='bottomRight'
                    trigger={['click']}
                    arrow='true'
                    onClick={(e) => {
                      changeadminnotifiaction('vendor');
                    }}
                  >
                    <div className='user-loggin'>
                      <Badge
                        count={
                          notificationcount > 10 ? '10+' : notificationcount
                        }
                      >
                        <BellOutlined style={{ fontSize: 25 }} />
                      </Badge>
                    </div>
                  </Dropdown>
                </li>
              )}
              {isLoggedIn && getLocalDataType() === 'admin' && (
                <li>
                  <Dropdown
                    overlay={adminnotificationMenu}
                    overlayClassName='admin_notifiction'
                    placement='bottomRight'
                    trigger={['click']}
                    arrow='true'
                    onClick={(e) => changeadminnotifiaction('admin')}
                  >
                    <div className='user-loggin'>
                      <Badge
                        count={
                          notificationcount > 10 ? '10+' : notificationcount
                        }
                      >
                        <BellOutlined style={{ fontSize: 25 }} />
                      </Badge>
                    </div>
                  </Dropdown>
                </li>
              )}
              {isLoggedIn && (
                <li>
                  <Dropdown
                    overlay={loginMenu}
                    overlayClassName='extra-menu'
                    placement='bottomRight'
                    trigger={['click']}
                    arrow='true'
                  >
                    {getLocalData('image_url') ? (
                      <div className='user-loggin'>
                        <Avatar src={getLocalData('image_url')} />
                        <Text>Hi, {getLocalData('firstname')}</Text>
                      </div>
                    ) : (
                      <div className='user-loggin'>
                        <Avatar size={40} icon={<UserOutlined />} />
                        <Text>Hi, {getLocalData('firstname')}</Text>
                      </div>
                    )}
                  </Dropdown>
                </li>
              )}

              {/*isLoggedIn ? (
                <li className="hambarger-menu">
                  <Dropdown
                    overlay={loginMenu}
                    overlayClassName="extra-menu"
                    placement="bottomRight"
                    trigger={["click"]}
                    arrow="true"
                  >
                    <a onClick={(e) => e.preventDefault()} />
                  </Dropdown>
                </li>
              ) : (
                ""
              )*/}
            </ul>
          </div>
        </div>
      </Layout.Header>

      <SignupModal
        visible={isModalVisible}
        onCancel={() => showModal({ signupModalVisible: false })}
        onFinish={onFinish}
        LoginSignup={LoginSignup}
        loader={loading}
      />

      <SigninModal
        visible={isModalLVisible}
        onCancel={() => showLModal({ loginModalVisible: false })}
        onLoginForgot={LoginForgot}
        onFinish={onFinishLogin}
        LoginSignup={LoginSignup}
        loader={loading}
      />

      <ForgotModal
        visible={isForgotVisible}
        onCancel={fOk}
        onFinish={onFinishForgot}
        onLoginForgot={LoginForgot}
        loader={loading}
      />

      <Modal
        title={false}
        visible={isOtpVisible}
        onCancel={otpOk}
        centered
        footer={false}
        className='modal-ui-1 modal-otp'
        width='100%'
        destroyOnClose
      >
        <div className='modal-body-ui'>
          <h2>OTP Verification</h2>
          <p className='sub'>
            Enter the OTP you received to <br /> {isemail}{' '}
          </p>
          <div className='ant-form-item-four'>
            <OTP OTPdata={OTPdata} onChangeOTP={onChangeOTP} />
          </div>
          <div className='ant-form-item'>
            <Button
              type='primary'
              disabled={loading}
              loading={loading}
              onClick={onFinishOTP}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Header;
