const actions = {
  GET_BUSINESS_CATEGORY_LIST_ADMIN: 'GET_BUSINESS_CATEGORY_LIST_ADMIN',
  POST_BUSINESS_CATEGORY: 'POST_BUSINESS_CATEGORY',
  GET_BUSINESS_CATEGORY_LIST: 'GET_BUSINESS_CATEGORY_LIST',
  BUSINESS_CATEGORY_LIST_RESPONSE: 'BUSINESS_CATEGORY_LIST_RESPONSE',
  GET_BUSINESS_CATEGORY: 'GET_BUSINESS_CATEGORY',
  BUSINESS_CATEGORY_RESPONSE: 'BUSINESS_CATEGORY_RESPONSE',
  CALL_LOADER: 'CALL_LOADER',
  CALL_BUSINESS_CATEGORY_SUCESS: 'CALL_BUSINESS_CATEGORY_SUCESS',
  DELETE_BUSINESS_CATEGORY: 'DELETE_BUSINESS_CATEGORY',
  UPDATE_BUSINESS_CATEGORY_OPEN: 'UPDATE_BUSINESS_CATEGORY_OPEN',
  CALL_BUSINESS_CATEGORY_UPDATE_SUCESS: 'CALL_BUSINESS_CATEGORY_UPDATE_SUCESS',
  STOP_LOOP: 'STOP_LOOP',
  PUBLIC_BUSINESS_CATEGORY_GETALL: 'PUBLIC_BUSINESS_CATEGORY_GETALL',

  GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_LATEST:
    'GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_LATEST',
  POST_VENDOR_BUSINESS_CATEGORY: 'POST_VENDOR_BUSINESS_CATEGORY',
  GET_VENDOR_BUSINESS_CATEGORY_LIST: 'GET_VENDOR_BUSINESS_CATEGORY_LIST',
  VENDOR_BUSINESS_CATEGORY_LIST_RESPONSE:
    'VENDOR_BUSINESS_CATEGORY_LIST_RESPONSE',
  GET_VENDOR_BUSINESS_CATEGORY: 'GET_VENDOR_BUSINESS_CATEGORY',
  VENDOR_BUSINESS_CATEGORY_RESPONSE: 'VENDOR_BUSINESS_CATEGORY_RESPONSE',
  DELETE_VENDOR_BUSINESS_CATEGORY: 'DELETE_VENDOR_BUSINESS_CATEGORY',

  GET_ADMIN_SERVICE_VENDOR_LIST: 'GET_ADMIN_SERVICE_HOTEL_LIST',
  GET_ADMIN_SERVICE_VENDOR_LIST_RESPONSE:
    'GET_ADMIN_SERVICE_HOTEL_LIST_RESPONSE',
  GET_SERVICES_LIST: 'GET_SERVICES_LIST',
  GET_SERVICES_LIST_RESPONSE: 'GET_SERVICES_LIST_RESPONSE',
  POST_SERVICES: 'POST_SERVICES',
  CALL_SERVICE_SUCESS: 'CALL_SERVICE_SUCESS',
  DELETE_SERVICE: 'DELETE_SERVICE',
  DELETE_SERVICE_LATEST: 'DELETE_SERVICE_LATEST',
  DELETE_SERVICE_LATEST_UNIQUE_SERVICE_BUSINESS:
    'DELETE_SERVICE_LATEST_UNIQUE_SERVICE_BUSINESS',

  GET_VENDOR_SERVICES_LIST: 'GET_VENDOR_SERVICES_LIST',
  GET_VENDOR_SERVICES_LIST_RESPONSE: 'GET_VENDOR_SERVICES_LIST_RESPONSE',
  POST_VENDOR_SERVICES_BUS: 'POST_VENDOR_SERVICES_BUS',
  DELETE_VENDOR_SERVICE: 'DELETE_VENDOR_SERVICE',

  GET_STAFF_LIST: 'GET_STAFF_LIST',
  GET_STAFF_LIST_RESPONSE: 'GET_STAFF_LIST_RESPONSE',
  GET_VENDOR_LIST: 'GET_VENDOR_LIST',
  GET_VENDOR_LIST_RESPONSE: 'GET_VENDOR_LIST_RESPONSE',

  GET_VENDORSTAFF_LIST: 'GET_VENDORSTAFF_LIST',
  GET_VENDORSTAFF_LIST_RESPONSE: 'GET_VENDORSTAFF_LIST_RESPONSE',

  GET_VENDOR_SERVICE_LISTDATA: 'GET_VENDOR_SERVICE_LISTDATA',
  GET_VENDOR_SERVICE_LISTDATA_RES: 'GET_VENDOR_SERVICE_LISTDATA_RES',
  GET_VENDOR_SERVICE_LISTDATA_REQ: 'GET_VENDOR_SERVICE_LISTDATA_REQ',

  POST_VENDOR_CREATE_BUSINESS_CATEGORY: 'POST_VENDOR_CREATE_BUSINESS_CATEGORY',
  POST_VENDOR_UPDATE_BUSINESS_CATEGORY: 'POST_VENDOR_UPDATE_BUSINESS_CATEGORY',

  GET_BUSINESS_CATEGORY_LISTDATA_VENDOR:
    'GET_BUSINESS_CATEGORY_LISTDATA_VENDOR',
  GET_BUSINESS_CATEGORY_LISTDATA_VENDOR_RESPONSE:
    'GET_BUSINESS_CATEGORY_LISTDATA_VENDOR_RESPONSE',
  DELETE_VENDORCREATED_BUSINESS_CATEGORY:
    'DELETE_VENDORCREATED_BUSINESS_CATEGORY',

  GET_VAENDOE_BUSINESS_CATEGORY_SERVICES_LIST:
    'GET_VAENDOE_BUSINESS_CATEGORY_SERVICES_LIST',
  GET_VAENDOE_BUSINESS_CATEGORY_SERVICES_LIST_RES:
    'GET_VAENDOE_BUSINESS_CATEGORY_SERVICES_LIST_RES',

  GET_VENDOR_STAFFTIME_LIST: 'GET_VENDOR_STAFFTIME_LIST',
  UPDATE_ADMIN_BUSINESS_CATEGORY_ENTRY: 'UPDATE_ADMIN_BUSINESS_CATEGORY_ENTRY',

  UPDATE_ADMIN_SERVICE_ENTRY: 'UPDATE_ADMIN_SERVICE_ENTRY',

  GET_VENDORBUSINESS_CATEGORY_LIST: 'GET_VENDORBUSINESS_CATEGORY_LIST',
  GET_VENDORBUSINESS_CATEGORY_LIST_RES: 'GET_VENDORBUSINESS_CATEGORY_LIST_RES',
  GET_VENDORBUSINESS_CATEGORY_LIST_FAILUE:
    'GET_VENDORBUSINESS_CATEGORY_LIST_FAILUE',

  GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST:
    'GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST',
  GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_WITH_LANGUAGE:
    'GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_WITH_LANGUAGE',
  GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_WITH_LANGUAGET_SUCCESS:
    'GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_WITH_LANGUAGET_SUCCESS',
  GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_SUCCESS:
    'GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_SUCCESS',
  GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_FAILURE:
    'GET_BUSINESS_CATEGORY_SERVICE_By_VENDOR_LIST_FAILURE',
  GET_BUSINESS_CATEGORY_SERVICE_BY_ADMIN_LIST:
    'GET_BUSINESS_CATEGORY_SERVICE_BY_ADMIN_LIST',
  GET_BUSINESS_CATEGORY_SERVICE_BY_ADMIN_LIST_SUCCESS:
    'GET_BUSINESS_CATEGORY_SERVICE_BY_ADMIN_LIST_SUCCESS',
  GET_BUSINESS_CATEGORY_SERVICE_BY_ADMIN_LIST_FAILURE:
    'GET_BUSINESS_CATEGORY_SERVICE_BY_ADMIN_LIST_FAILURE',

  GET_VENDOR_DROPDOWN_LISTDATA: 'GET_VENDOR_DROPDOWN_LISTDATA',
  GET_VENDOR_DROPDOWN_LISTDATA_RES: 'GET_VENDOR_DROPDOWN_LISTDATA_RES',
  GET_VENDOR_DROPDOWN_LISTDATA_REQ: 'GET_VENDOR_DROPDOWN_LISTDATA_REQ',

  GET_VENDOR_STAFF_SERVICE: 'GET_VENDOR_STAFF_SERVICE',
  GET_VENDOR_STAFF_SERVICE_RES: 'GET_VENDOR_STAFF_SERVICE_RES',
  GET_VENDOR_STAFF_SERVICE_REQ: 'GET_VENDOR_STAFF_SERVICE_REQ',
};

export default actions;
