import { all, put, call, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { getRequest, putRequest } from 'helpers/axiosClient';
import { message } from 'antd';
import { getLocaleMessages } from 'redux/helper';
export function* getvendorTimeslot(params) {
  try {
    const response = yield call(() =>
      getRequest(
        'admin/adminvendor/adminvendortimeGetall?vendorid=' + params.vendorid
      )
    );
    yield put({
      type: actions.GET_ADMIN_TIME_LIST_RES,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({
      type: actions.GET_ADMIN_TIME_LIST_FAILURE,
    });
    message.error(error?.response?.data?.message);
  }
}

export function* getadminVendorList(params) {
  try {
    const response = yield call(() =>
      getRequest('admin/adminvendor/getall?status=1')
    );
    yield put({
      type: actions.GET_ADMIN_VENDOR_LIST_RES,
      payload: response.data.data,
    });
  } catch (error) {
    message.error(error?.response?.data?.message);
  }
}
export function* putvendorTimeslot(params) {
  try {
    const response = yield call(() =>
      putRequest('admin/adminvendor/timeupdate', params)
    );
    yield put({
      type: actions.GET_ADMIN_TIME_LIST,
      vendorid: params.vendorid,
    });
    message.success(
      getLocaleMessages({ id: 'Timeslot Updated successfully!' })
    );
  } catch (error) {
    yield put({
      type: actions.UPDATE_ADMIN_TIME_FAILURE,
    });
    message.error(error?.response?.data?.message);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ADMIN_TIME_LIST, getvendorTimeslot),
    takeEvery(actions.UPDATE_ADMIN_TIME, putvendorTimeslot),
    takeEvery(actions.GET_ADMIN_VENDOR_LIST, getadminVendorList),
  ]);
}
