import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Input, Form, Checkbox, Button, Select, Spin } from "antd";
import { Country } from "country-state-city";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "assets/css/style.scss";
import "assets/css/login-signup.scss";
import { CloseOutlined } from "@ant-design/icons";
import { getLocaleMessages } from "redux/helper";
import { useLocation } from "react-router-dom";

const Countries = Country.getAllCountries();

const Signup = (props) => {
  const { onFinish, loader, load } = props;
  const location = useLocation();
  const [phvalue, setPhvalue] = useState(null);

  useEffect(() => {
    localStorage.setItem("lastpath", location.pathname);
  }, []);

  return (
    <>
      <section className="login-dashboard signup-dashboard">
        <div className="login-box">
          <NavLink to={"/"} className="back_to_page">
            <CloseOutlined />
          </NavLink>
          <Spin size="large" spinning={load}>
            <div className="login-headers">
              <div>
                <NavLink to={"/"}>
                  <img
                    src={require("../../assets/img/logo-clolor.png")}
                    alt=""
                  />
                </NavLink>
              </div>

              <h2>Sign up</h2>
            </div>
            <Form
              name="normal_login"
              className="login-form"
              onFinish={onFinish}
              initialValues={{
                mobile_number: {
                  countrycode: "+966",
                },
              }}
            >
              <Form.Item
                name="firstname"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your First Name!",
                  },
                  {
                    pattern: new RegExp(/[\u0600-\u06FF a-zA-Z]+/g),
                    message: "You can't use number here!",
                  },
                  // { min: 5, message: 'First Name must be minimum 5 characters.' },
                ]}
              >
                <Input
                  placeholder={getLocaleMessages({
                    id: "placeholder.firstName",
                  })}
                />
              </Form.Item>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your Last Name!",
                  },
                  {
                    pattern: new RegExp(/[\u0600-\u06FF a-zA-Z]+/g),
                    message: "You can't use number here!",
                  },
                ]}
              >
                <Input
                  placeholder={getLocaleMessages({
                    id: "placeholder.lastName",
                  })}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your Email!",
                  },
                  {
                    type: "email",
                    whitespace: true,
                    message: "Invalid email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Input.Group compact>
                <Form.Item
                  name={"phonenumber"}
                  rules={[
                    {
                      required: true,
                      message: "Phone number required",
                    },
                    {
                      message: `Invalid Phone No`,
                      validator: (_, value) => {
                        if (isValidPhoneNumber(`${value}`)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject();
                        }
                      },
                    },
                  ]}
                >
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    placeholder="Enter phone number"
                    defaultCountry="SA"
                    value={phvalue}
                    onChange={setPhvalue}
                  />
                </Form.Item>
              </Input.Group>

              <Form.Item
                name={"usercountryname"}
                rules={[{ required: true, message: "Country is required" }]}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  autoComplete={"off"}
                  placeholder={"Please select the country"}
                  dropdownStyle={{ minWidth: "200px" }}
                >
                  {Countries &&
                    Countries.length &&
                    Countries.map((item, index) => {
                      return (
                        <Select.Option value={item.name} key={index}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your Password!",
                  },
                  {
                    min: 6,
                    message: "Password must be minimum 6 characters.",
                  },
                  {
                    max: 16,
                    message: "Password can be maximum 16 characters.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
              <Form.Item
                className="terms-conditions"
                name="remember"
                valuePropName="checked"
                validateTrigger={["onSubmit"]}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            "Please accept the terms & conditions!"
                          ),
                  },
                ]}
              >
                <Checkbox>
                  I agree to the &nbsp;
                  <a
                    className="login-form-forgot"
                    href="/cmspage/28"
                    target="_blank"
                  >
                    Terms &amp; Conditions
                  </a>
                </Checkbox>
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loader}
              >
                Sign Up
              </Button>
            </Form>
            <span className="or">&nbsp;</span>
            <NavLink to={"/auth?type=login"} className="new">
              Already have an account? <span>Sign in</span>
            </NavLink>{" "}
            <div className="become-partner">
              <h2>Become a partner</h2>

              <NavLink to={"/partner-register"} className="new">
                Manage your business with Lamsat by__{" "}
                <span> signing up as a professional</span>{" "}
              </NavLink>
            </div>
          </Spin>
        </div>
      </section>
    </>
  );
};

export default Signup;
