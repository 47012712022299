import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Layout,
  Button,
  Menu,
  Modal,
  Dropdown,
  Avatar,
  Typography,
  message,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'redux/auth/actions';
import {
  ExclamationCircleOutlined,
  UserOutlined,
  BellOutlined,
} from '@ant-design/icons';
import adminvendorprofileAction from 'redux/admin/adminvendorprofile/actions';
import SignupModal from 'containers/Layouts/SignupModal';
import SigninModal from 'containers/Layouts/SigninModal';
import ForgotModal from 'containers/Layouts/ForgotModal';
import OTP from 'containers/Layouts/OTP';
import 'assets/css/header.scss';
import 'assets/css/modal.scss';
import 'assets/css/responsive.scss';
import { getLocalDataType, getLocalData } from 'redux/helper';
import { store } from 'redux/store';
import Autocomplete from 'react-google-autocomplete';
import ListingLayoutAction from 'redux/ListingLayout/actions';
import layoutActions from 'redux/Layout/actions';
import { logoutChannel } from './Auth';
const { Text } = Typography;

const Header = (props) => {
  useEffect(() => {
    store.dispatch({
      type: layoutActions.GET_LAYOUT_CATEGORIES,
    });
  }, []);
  const latRef = useRef();
  const lngRef = useRef();
  const [drawer, setDrawer] = useState(false);

  const handleVisibleDraw = () => {
    setDrawer(!drawer);
    const inputSelect = autoCompleteInputRef.current.querySelectorAll('input');
    inputSelect.forEach((singleInput) => (singleInput.value = ''));
  };

  const { isLoggedIn, isOtp, isemail } = useSelector((state) => state.Auth);
  const { loading } = useSelector((state) => state.App);
  const LayoutListing = useSelector((state) => state.ListingLayout);
  const { notificationData } = useSelector((state) => state.AdminVendorProfile);

  const {
    locationName,
    //vendorbynameData,
    formattedAddress,
  } = LayoutListing;
  const dispatch = useDispatch();
  const autoCompleteInputRef = useRef();
  //modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLVisible, setIsModalLVisible] = useState(false);
  const [isForgotVisible, setIsForgotVisible] = useState(false);
  const [isOtpVisible, SetIsOtpVisible] = useState(false);
  const [OTPdata, setOTPdata] = useState('');
  const [getLatitude, setLatitude] = useState(0);
  const [getLongitude, setLongitude] = useState(0);
  const [selectedLocation, setselectedLocation] = useState('');
  let formatted_address = localStorage.getItem('formatted_address');
  const [cityName, setCityName] = useState('');
  const [notificationlist, setnotificationlist] = useState([]);
  const [notificationcount, setnotificationcount] = useState(0);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [forMatAdd, setForMatAdd] = useState(null);
  const currentPageLocation = useLocation();
  const option = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 1000,
  };

  useEffect(() => {
    if (notificationData.length) {
      setnotificationlist(notificationData[0]);
      setnotificationcount(notificationData[1]);
    }
  }, [notificationData]);

  useEffect(() => {
    if (currentLocation === null) return;
    const data = {};
    data['latitude'] = getLatitude;
    data['longitude'] = getLongitude;
    if (cityName) {
      data['vendoraddress'] = cityName;
    }
  }, [selectedLocation, currentLocation]);

  useEffect(() => {
    if (currentLocation !== null) {
      dispatch({
        type: ListingLayoutAction.SET_CURRENT_LATLONG,
        payload: [
          {
            latitude: currentLocation?.latitude,
            longitude: currentLocation?.longitude,
          },
        ],
      });
    }
  }, [currentLocation]);

  useEffect(() => {
    if (isOtpVisible) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [isOtpVisible]);

  useEffect(() => {
    if (isOtp) {
      setIsModalVisible(false);
      SetIsOtpVisible(isOtp);
    } else {
      SetIsOtpVisible(false);
      setOTPdata('');
    }
  }, [isOtp]);

  useEffect(() => {
    if (getLocalDataType() === 'user') {
      dispatch({
        type: adminvendorprofileAction.GET_ALL_NOTIFICATION,
        payload: {
          userid: getLocalData('id'),
          usertype: 'user',
          isviewed: 0,
        },
      });
    }
  }, []);
  const showModal = ({ signupModalVisible }) => {
    setIsModalVisible(signupModalVisible);
  };

  const showLModal = ({ loginModalVisible }) => {
    setIsModalLVisible(loginModalVisible);
  };

  const fOk = () => {
    setIsForgotVisible(false);
  };

  const LoginForgot = ({ login, forgot }) => {
    if (forgot) {
      setIsModalLVisible(login);
      setIsForgotVisible(forgot);
    } else if (login) {
      setIsForgotVisible(forgot);
      setIsModalLVisible(login);
    }
  };

  const searchLocation = (place) => {
    if (place.name === undefined) {
      const cityName = place.address_components[0].long_name;
      setCityName(cityName);
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      setLatitude(latitude);
      setLongitude(longitude);
      setselectedLocation(place.formatted_address);
      localStorage.setItem('formatted_address', place.formatted_address);
      localStorage.setItem('cityName', cityName);

      dispatch({
        type: ListingLayoutAction.GET_CURRENT_LATLONG,
        payload: [
          {
            latitude: latitude,
            longitude: longitude,
          },
        ],
      });
    }
  };

  const LoginSignup = ({ login, signup }) => {
    if (login) {
      setIsModalVisible(signup);
      setIsModalLVisible(login);
    } else if (signup) {
      setIsModalLVisible(login);
      setIsModalVisible(signup);
    }
  };

  const otpOk = () => {
    Modal.confirm({
      title: 'Warning',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to close this modal and the otp modal never open',
      okText: 'ok',
      cancelText: 'cancel',
      maskClosable: true,
      mask: true,
      onOk: () => {
        dispatch({
          type: actions.VERIFY_OTP_SUCCESS,
        });
      },
    });
  };

  const onFinish = (values) => {
    values = {
      ...values,
      ...values['mobile_number'],
      confirmpassword: values['password'],
      cityid: 1,
      countryid: 1,
    };
    dispatch({
      type: actions.CREATE_AUTHENTICATE_USER,
      payload: values,
    });
  };

  const onFinishLogin = (values) => {
    dispatch({
      type: actions.AUTHENTICATE_USER,
      payload: values,
      callBackAction: () => {
        showLModal({ loginModalVisible: false });
      },
    });
  };

  const onFinishForgot = (values) => {
    dispatch({
      type: actions.SEND_PASSWORD_RESET_LINK,
      payload: values,
      callBackAction: () => {
        LoginForgot({
          login: true,
          forgot: false,
        });
      },
    });
  };

  const onFinishOTP = () => {
    if (OTPdata.length === 5) {
      dispatch({
        type: actions.VERIFY_OTP,
        payload: {
          otp: OTPdata,
          email: isemail,
        },
        callBackAction: (status) => {
          if (status) {
            SetIsOtpVisible(false);
            setOTPdata('');
          }
        },
      });
    } else {
      message.error('please enter valid OTP');
    }
  };

  const onChangeOTP = (value) => {
    setOTPdata(value);
  };
  const loginMenu = (
    <Menu>
      {/* <Menu.Item>
        <Link to="/user-dashboard">Dashboard</Link>
      </Menu.Item> */}
      <Menu.Item>
        <Link to={'/bookings'}>Bookings</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={'/favourites'}>Favourites</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to='/edit-profile'>Edit Profile</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={'/rate-reviews'}>Rate & Reviews</Link>
      </Menu.Item>
      <Menu.Item
        key='logout'
        onClick={() => {
          dispatch({
            type: actions.LOGOUT_USER,
          });
          logoutChannel.postMessage('Logout');
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const usernotificationMenu = (
    <Menu style={{ overflow: 'auto' }}>
      {notificationlist.filter((ma) => ma.isviewed !== 1).length ? (
        notificationlist
          .filter((ma) => ma.isviewed !== 1)
          .map((data) => {
            return (
              <Menu.Item
                key={data.id}
                onClick={() => {
                  // dispatch({
                  //   type: NotificationActions.SET_NOTIFICATION,
                  //   payload: {
                  //     id: data.id,
                  //   },
                  // });
                }}
              >
                <a href='notification'>
                  {data.notification_content.en && data.notification_content.en}
                </a>
              </Menu.Item>
            );
          })
      ) : (
        <Menu.Item>
          <a href='notification'>No Notifications found</a>
        </Menu.Item>
      )}
    </Menu>
  );
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function success({ coords }) {
        // console.log(coords,"pageInitialValue")
        latRef.current = coords.latitude;
        lngRef.current = coords.longitude;
        setLatitude(coords.latitude);
        setLongitude(coords.longitude);
        setCurrentLocation(coords);
        if (coords.latitude !== undefined && coords.longitude !== undefined) {
          dispatch({
            type: ListingLayoutAction.GET_CURRENT_ADDRESS_STRING,
            payload: {
              lat: coords.latitude,
              lng: coords.longitude,
              key: 'AIzaSyD2fIwEEQ7r4n9OSVvOBMblCVCxfz23aro',
            },
            callBackAction: (res) => {
              setForMatAdd(res.data.results[0].formatted_address);
              localStorage.setItem(
                'formatted_address',
                res.data.results[0].formatted_address
              );
            },
          });
        }
      },
      console.warn,
      option
    );
  }, [latRef, lngRef, currentPageLocation.pathname]);
  useEffect(() => {
    formatted_address = localStorage.getItem('formatted_address');
  }, [localStorage]);
  const clearNotification = () => {
    dispatch({
      type: adminvendorprofileAction.CLEAR_ALL_NOTIFICATION,
      payload: {
        userid: getLocalData('id'),
        usertype: 'user',
        isviewed: 1,
      },
      callBackAction: (status) => {
        if (status) {
          setnotificationcount(0);
        }
      },
    });
  };
  return (
    <>
      <Layout.Header className='site-header'>
        <div className='container'>
          <div className='logo'>
            <Link
              to={{
                pathname: ['admin', 'vendor'].includes(getLocalDataType())
                  ? `/${getLocalDataType()}/dashboard`
                  : '/',
              }}
            >
              <img
                src={require('../../assets/img/logo-clolor.png')}
                alt=''
                className='img-fluid'
              />
            </Link>
          </div>
          {(currentPageLocation.pathname === '/listing' ||
            currentPageLocation.pathname === '/detail') && (
            <div className='search-and-location'>
              <div className='search_box' ref={autoCompleteInputRef}>
                <Autocomplete
                  className='custom-autocomplete'
                  apiKey={'AIzaSyD2fIwEEQ7r4n9OSVvOBMblCVCxfz23aro'}
                  onPlaceSelected={(place) => {
                    searchLocation(place);
                  }}
                  defaultValue={
                    formatted_address !== null ||
                    formatted_address !== undefined ||
                    formatted_address !== ''
                      ? formatted_address
                      : /*: locationName !== ""&&locationName!==undefined
                      ? locationName*/
                      forMatAdd !== null
                      ? forMatAdd
                      : 'Saudi Arabia'
                  }
                />
                <span className='location' onClick={handleVisibleDraw}>
                  <h3
                    style={{
                      textDecoration: 'none',
                      borderRadius: '50%',
                      backgroundColor: '#f9a392',
                      color: 'white',
                      padding: ' 5px 10px',
                      margin: '0px 5px',
                      fontSize: '14px',
                    }}
                  >
                    x
                  </h3>
                </span>
              </div>
              {/* {drawer ? () : (
                <Button
                  className="transparentButton"
                  onClick={handleVisibleDraw}
                >
                  <span className="in">Infgfhjhjhg</span>
                  <span className={"location"}>
                    {formatted_address
                      ? formatted_address
                      : locationName !== ""
                      ? locationName
                      : "Saudi Arabia"}
                  </span>
                </Button>
              )} */}
            </div>
          )}

          <div className='menu-navigation'>
            <Menu theme='light' mode='horizontal'>
              <Menu.Item className='border-right-none' key={'language'}>
                {/*<Button type="dashed" onClick={selectedLanguage["id"]==2 ? () => handleChangeLanguage(1):() => handleChangeLanguage(2)}>

          {localStorage.getItem("site_language_full")=='English'?'Arabic':'English'}
      </Button>*/}
                {/* <Select
              value={selectedLanguage["id"]}
              onChange={handleChangeLanguage}
              loading={languageLoader}
              disabled={languageLoader}
            >
              {getAppLanguageList.map((list, index) => (
                <Option value={list["id"]} key={index}>
                  {list["languagename"]}
                </Option>
              ))}
              </Select>*/}
              </Menu.Item>
            </Menu>
            <ul>
              {!isLoggedIn && (
                <>
                  <li>
                    <Link to='/signup'>Sign up</Link>{' '}
                  </li>
                  <li>
                    <Link to='/login'>Login</Link>{' '}
                  </li>
                </>
              )}
              {isLoggedIn && getLocalDataType() === 'user' && (
                <li>
                  <Dropdown
                    overlay={usernotificationMenu}
                    overlayClassName='extra-menu'
                    placement='bottomRight'
                    trigger={['click']}
                    arrow='true'
                    onClick={(e) => {
                      notificationData.length > 0 && clearNotification();
                      // console.log("user");
                    }}
                  >
                    <div className='user-loggin'>
                      <BellOutlined style={{ fontSize: 25 }} />
                      <span style={{ marginBottom: '30px', color: 'red' }}>
                        {notificationcount > 10 ? '10+' : notificationcount}
                      </span>
                    </div>
                  </Dropdown>
                </li>
              )}
              {isLoggedIn && (
                <li>
                  <Dropdown
                    overlay={loginMenu}
                    overlayClassName='extra-menu'
                    placement='bottomRight'
                    trigger={['click']}
                    arrow='true'
                  >
                    {getLocalData('image_url') ? (
                      <div className='user-loggin'>
                        <Avatar src={getLocalData('image_url')} />
                        <Text>Hi, {getLocalData('firstname')}</Text>
                      </div>
                    ) : (
                      <div className='user-loggin'>
                        <Avatar size={40} icon={<UserOutlined />} />
                        <Text>Hi, {getLocalData('firstname')}</Text>
                      </div>
                    )}
                  </Dropdown>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Layout.Header>

      {/*Modal*/}
      <SignupModal
        visible={isModalVisible}
        onCancel={() => showModal({ signupModalVisible: false })}
        onFinish={onFinish}
        LoginSignup={LoginSignup}
        loader={loading}
      />

      <SigninModal
        visible={isModalLVisible}
        onCancel={() => showLModal({ loginModalVisible: false })}
        onLoginForgot={LoginForgot}
        onFinish={onFinishLogin}
        LoginSignup={LoginSignup}
        loader={loading}
      />

      <ForgotModal
        visible={isForgotVisible}
        onCancel={fOk}
        onFinish={onFinishForgot}
        onLoginForgot={LoginForgot}
        loader={loading}
      />

      <Modal
        title={false}
        visible={isOtpVisible}
        onCancel={otpOk}
        centered
        footer={false}
        className='modal-ui-1 modal-otp'
        width='100%'
        destroyOnClose
      >
        <div className='modal-body-ui'>
          <h2>OTP Verification</h2>
          <p className='sub'>
            Enter the OTP you received to <br /> {isemail}{' '}
          </p>
          <div className='ant-form-item-four'>
            <OTP OTPdata={OTPdata} onChangeOTP={onChangeOTP} />
          </div>
          <div className='ant-form-item'>
            <Button
              type='primary'
              disabled={loading}
              loading={loading}
              onClick={onFinishOTP}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Header;
