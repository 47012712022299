const initState = {
  loader: true,
};

export default function loaderReducer(state = initState, action) {
  switch (action.type) {
    case "SET_LOADER_ON": {
      // console.log("LOADER_ON");
      return {
        ...state,
        loader: true,
      };
    }

    case "SET_LOADER_OFF": {
      // console.log("LOADER_OFF");
      return {
        ...state,
        loader: false,
      };
    }

    default:
      return state;
  }
}
