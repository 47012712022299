const actions = {
    MODAL_SHOW_CMS: 'MODAL_SHOW_CMS',
    MODAL_SHOW_FAQ: 'MODAL_SHOW_FAQ',
    GET_LANG_LIST: 'GET_LANG_LIST',
    GET_LANG_LIST_RESPONSE: 'GET_LANG_LIST_RESPONSE',
    GET_CMS_LIST: 'GET_CMS_LIST',
    GET_CMS_LIST_RESPONSE: 'GET_CMS_LIST_RESPONSE',
    GET_FAQ_LIST: 'GET_FAQ_LIST',
    GET_FAQ_LIST_RESPONSE: 'GET_FAQ_LIST_RESPONSE',
    POST_CMS: 'POST_CMS',
    POST_FAQ: 'POST_FAQ',
    FIND_CMS: 'FIND_CMS',
    FIND_FAQ: 'FIND_FAQ',
    DELETE_FAQ: 'DELETE_FAQ',
    DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
    DELETE_FAQ_FAILURE: 'DELETE_FAQ_FAILURE',
    DELETE_CMS: 'DELETE_CMS',
    DELETE_CMS_SUCCESS: 'DELETE_CMS_SUCCESS',
    DELETE_CMS_FAILURE: 'DELETE_CMS_FAILURE',

    SET_CMS_PAGE_NUMBER: 'SET_CMS_PAGE_NUMBER',
    SET_CMS_PAGE_SIZE: 'SET_CMS_PAGE_SIZE',
    SET_CMS_FILTER_NAME: 'SET_CMS_FILTER_NAME',
    SET_CMS_FILTER_LOG: 'SET_CMS_FILTER_LOG',    
    CMS_REDIRECT : 'CMS_REDIRECT',
    FAQ_REDIRECT : 'FAQ_REDIRECT',
    CMS_STATUS : 'CMS_STATUS',
    CREATE_CMS : 'CREATE_CMS',
    CREATE_CMS_SUCCESS : 'CREATE_CMS_SUCCESS',
    CREATE_CMS_FAILURE : 'CREATE_CMS_FAILURE',
    DESCRIPTION : 'DESCRIPTION',
    PAGETITLE:'PAGETITLE',
    KEYWORDS:'KEYWORDS',
    SLUG:'SLUG',
    SORTORDER:'SORTORDER',
    ENG_CONTENT:'ENG_CONTENT',
    ARB_CONTENT:'ARB_CONTENT',
    ENG_PAGETITLE:'ENG_PAGETITLE',
    ARB_PAGETITLE:'ARB_PAGETITLE',
    GET_SINGLE_CMS_LIST_RESPONSE : 'GET_SINGLE_CMS_LIST_RESPONSE',
    GET_SINGLE_CMS : 'GET_SINGLE_CMS',
    UPDATE_CMS : "UPDATE_CMS",
    FAQ_STATUS : "FAQ_STATUS",
    ALL_ANSWER : "ALL_ANSWER",
    ALL_QUESTION : "ALL_QUESTION",
    CREATE_FAQ : 'CREATE_FAQ',
    UPDATE_FAQ : 'UPDATE_FAQ',
    GET_SINGLE_FAQ : 'GET_SINGLE_FAQ',
    GET_SINGLE_FAQ_LIST_RESPONSE : 'GET_SINGLE_FAQ_LIST_RESPONSE',
    ALL_PAGETITLE : 'ALL_PAGETITLE',
    ALL_PAGECONTENT : 'ALL_PAGECONTENT',
    LOADER_SHOW: 'LOADER_SHOW',
    ASSIGN_SALOON_LANGUAGE: 'ASSIGN_SALOON_LANGUAGE',
    INITIAL_LOAD:'INITIAL_LOAD',
    CKEDITOR_OUTPUT1: 'CKEDITOR_OUTPUT1',
    CKEDITOR_OUTPUT2: 'CKEDITOR_OUTPUT2',
    SET_SALOON_LANGUAGE_DETAILS: 'SET_SALOON_LANGUAGE_DETAILS',
    ASSIGN_PAGE_LANGUAGE : 'ASSIGN_PAGE_LANGUAGE',
    SET_PAGE_LANGUAGE_DETAILS : 'SET_PAGE_LANGUAGE_DETAILS',
    SET_PAGECONTENT_LANGUAGE_DETAILS : 'SET_PAGECONTENT_LANGUAGE_DETAILS',
    CMS_CONTENT : 'CMS_CONTENT',
    SET_LANGUAGE_DETAILS : 'SET_LANGUAGE_DETAILS',
    SET_SALOON_LANGUAGE_DETAILS_ALL : 'SET_SALOON_LANGUAGE_DETAILS_ALL',
    LOADED: 'LOADED',
    SET_LANGUAGE_ALL_DETAILS: 'SET_LANGUAGE_ALL_DETAILS',
    CMS_PAGEMANAGEMENT:'CMS_PAGEMANAGEMENT'
}

export default actions;