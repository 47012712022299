const actions = {
  GET_CATEGORY_LIST_ADMIN: "GET_CATEGORY_LIST_ADMIN",
  POST_CATEGORY: "POST_CATEGORY",
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  CATEGORY_LIST_RESPONSE: "CATEGORY_LIST_RESPONSE",
  CATEGORY_LIST_RESPONSE_SUCCESS: "CATEGORY_LIST_RESPONSE_SUCCESS",
  CATEGORY_LIST_RESPONSE_FAILURE: "CATEGORY_LIST_RESPONSE_FAILURE",
  GET_CATEGORY: "GET_CATEGORY",
  CATEGORY_RESPONSE: "CATEGORY_RESPONSE",
  CALL_LOADER: "CALL_LOADER",
  CALL_CATEGORY_SUCESS: "CALL_CATEGORY_SUCESS",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  UPDATE_CATEGORY_OPEN: "UPDATE_CATEGORY_OPEN",
  CALL_CATEGORY_UPDATE_SUCESS: "CALL_CATEGORY_UPDATE_SUCESS",
  STOP_LOOP: "STOP_LOOP",

  GET_VENDOR_CATEGORY: "GET_VENDOR_CATEGORY",
  GET_VENDOR_CATEGORY_SUCCESS: "GET_VENDOR_CATEGORY_SUCCESS",

  GET_CATEGORY_SERVICE_By_VENDOR_LIST_LATEST:
    "GET_CATEGORY_SERVICE_By_VENDOR_LIST_LATEST",
  POST_VENDOR_CATEGORY: "POST_VENDOR_CATEGORY",
  GET_VENDOR_CATEGORY_LIST: "GET_VENDOR_CATEGORY_LIST",
  VENDOR_CATEGORY_LIST_RESPONSE: "VENDOR_CATEGORY_LIST_RESPONSE",
  VENDOR_CATEGORY_RESPONSE: "VENDOR_CATEGORY_RESPONSE",
  DELETE_VENDOR_CATEGORY: "DELETE_VENDOR_CATEGORY",

  GET_ADMIN_SERVICE_VENDOR_LIST: "GET_ADMIN_SERVICE_HOTEL_LIST",
  GET_ADMIN_SERVICE_VENDOR_LIST_RESPONSE:
    "GET_ADMIN_SERVICE_HOTEL_LIST_RESPONSE",
  GET_SERVICES_LIST: "GET_SERVICES_LIST",
  GET_SERVICES_LIST_RESPONSE: "GET_SERVICES_LIST_RESPONSE",
  POST_SERVICES: "POST_SERVICES",
  CALL_SERVICE_SUCESS: "CALL_SERVICE_SUCESS",
  DELETE_SERVICE: "DELETE_SERVICE",
  DELETE_SERVICE_LATEST: "DELETE_SERVICE_LATEST",
  DELETE_SERVICE_LATEST_UNIQUE_SERVICE: "DELETE_SERVICE_LATEST_UNIQUE_SERVICE",

  GET_VENDOR_SERVICES_LIST: "GET_VENDOR_SERVICES_LIST",
  GET_VENDOR_SERVICES_LIST_RESPONSE: "GET_VENDOR_SERVICES_LIST_RESPONSE",
  POST_VENDOR_SERVICES: "POST_VENDOR_SERVICES",
  VENDOR_SERVICES_IUD: "VENDOR_SERVICES_IUD",
  DELETE_VENDOR_SERVICE_ADMIN: "DELETE_VENDOR_SERVICE_ADMIN",

  GET_STAFF_LIST: "GET_STAFF_LIST",
  GET_STAFF_LIST_RESPONSE: "GET_STAFF_LIST_RESPONSE",
  GET_VENDOR_LIST: "GET_VENDOR_LIST",
  GET_VENDOR_LIST_RESPONSE: "GET_VENDOR_LIST_RESPONSE",
  GET_VENDOR_BY_ID: "GET_VENDOR_BY_ID",

  GET_VENDORSTAFF_LIST: "GET_VENDORSTAFF_LIST",
  GET_VENDORSTAFF_LIST_RESPONSE: "GET_VENDORSTAFF_LIST_RESPONSE",

  GET_VENDOR_SERVICE_LISTDATA: "GET_VENDOR_SERVICE_LISTDATA",
  GET_VENDOR_SERVICE_LISTDATA_RES: "GET_VENDOR_SERVICE_LISTDATA_RES",
  GET_VENDOR_SERVICE_LISTDATA_REQ: "GET_VENDOR_SERVICE_LISTDATA_REQ",

  POST_VENDOR_CREATE_CATEGORY: "POST_VENDOR_CREATE_CATEGORY",
  POST_VENDOR_UPDATE_CATEGORY: "POST_VENDOR_UPDATE_CATEGORY",
  VENDOR_CREATE_SUCCESS: "VENDOR_CREATE_SUCCESS",
  GET_CATEGORY_LISTDATA_VENDOR: "GET_CATEGORY_LISTDATA_VENDOR",
  GET_CATEGORY_LISTDATA_VENDOR_RESPONSE:
    "GET_CATEGORY_LISTDATA_VENDOR_RESPONSE",
  DELETE_VENDORCREATED_CATEGORY: "DELETE_VENDORCREATED_CATEGORY",

  GET_VAENDOE_CATEGORY_SERVICES_LIST: "GET_VAENDOE_CATEGORY_SERVICES_LIST",
  GET_VAENDOE_CATEGORY_SERVICES_LIST_RES:
    "GET_VAENDOE_CATEGORY_SERVICES_LIST_RES",

  GET_VENDOR_STAFFTIME_LIST: "GET_VENDOR_STAFFTIME_LIST",
  UPDATE_ADMIN_CATEGORY_ENTRY: "UPDATE_ADMIN_CATEGORY_ENTRY",

  UPDATE_ADMIN_SERVICE_ENTRY: "UPDATE_ADMIN_SERVICE_ENTRY",

  GET_VENDORCATEGORY_LIST: "GET_VENDORCATEGORY_LIST",
  GET_VENDORCATEGORY_LIST_RES: "GET_VENDORCATEGORY_LIST_RES",
  GET_VENDORCATEGORY_LIST_FAILUE: "GET_VENDORCATEGORY_LIST_FAILUE",

  GET_CATEGORY_SERVICE_By_VENDOR_LIST: "GET_CATEGORY_SERVICE_By_VENDOR_LIST",
  GET_CATEGORY_SERVICE_By_VENDOR_LIST_WITH_LANGUAGE:
    "GET_CATEGORY_SERVICE_By_VENDOR_LIST_WITH_LANGUAGE",
  GET_CATEGORY_SERVICE_By_VENDOR_LIST_WITH_LANGUAGET_SUCCESS:
    "GET_CATEGORY_SERVICE_By_VENDOR_LIST_WITH_LANGUAGET_SUCCESS",
  GET_CATEGORY_SERVICE_By_VENDOR_LIST_SUCCESS:
    "GET_CATEGORY_SERVICE_By_VENDOR_LIST_SUCCESS",
  GET_CATEGORY_SERVICE_By_VENDOR_LIST_FAILURE:
    "GET_CATEGORY_SERVICE_By_VENDOR_LIST_FAILURE",
  GET_CATEGORY_SERVICE_BY_ADMIN_LIST: "GET_CATEGORY_SERVICE_BY_ADMIN_LIST",
  GET_CATEGORY_SERVICE_BY_ADMIN_LIST_SUCCESS:
    "GET_CATEGORY_SERVICE_BY_ADMIN_LIST_SUCCESS",
  GET_CATEGORY_SERVICE_BY_ADMIN_LIST_FAILURE:
    "GET_CATEGORY_SERVICE_BY_ADMIN_LIST_FAILURE",

  GET_VENDOR_DROPDOWN_LISTDATA: "GET_VENDOR_DROPDOWN_LISTDATA",
  GET_VENDOR_DROPDOWN_LISTDATA_RES: "GET_VENDOR_DROPDOWN_LISTDATA_RES",
  GET_VENDOR_DROPDOWN_LISTDATA_REQ: "GET_VENDOR_DROPDOWN_LISTDATA_REQ",

  GET_VENDOR_STAFF_SERVICE: "GET_VENDOR_STAFF_SERVICE",
  GET_VENDOR_STAFF_SERVICE_RES: "GET_VENDOR_STAFF_SERVICE_RES",
  GET_VENDOR_STAFF_SERVICE_REQ: "GET_VENDOR_STAFF_SERVICE_REQ",
  GET_VENDOR_SERVICE_ID_SUCCESS: "GET_VENDOR_SERVICE_ID_SUCCESS",
};

export default actions;
