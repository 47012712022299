import actions from "./actions";

const initState = {
  listingSaloonLoader: true,
  listingSaloonData: [],
  categorysaloonDetail: [],
  saloonNameList: [],
  initialListingLoader: true,
  vendorbynameData: [],
  latlongData: [
   
  ],
  bookingList: [],
  vendorRatingAvg: [],
  formattedAddress:""
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_LISTING_SALOON: {
      return {
        ...state,
        listingSaloonLoader: true,
      };
    }
    case actions.GET_LISTING_SALOON_SUCCESS: {
      return {
        ...state,
        listingSaloonLoader: false,
        initialListingLoader: false,
        listingSaloonData: action.payload[0],
        categorysaloonDetail: [],
      };
    }

    case actions.GET_LOGIN_LISTING_SALOON: {
      return {
        ...state,
        listingSaloonLoader: true,
        initialListingLoader: true,
        listingSaloonData: [],
        categorysaloonDetail: [],
      };
    }

    case actions.GET_LISTING_SALOON_FAILURE: {
      return {
        ...state,
        listingSaloonLoader: false,
        initialListingLoader: false,
        listingSaloonData: [],
        categorysaloonDetail: [],
      };
    }
    case actions.GET_LOGIN_LISTING_SALOON: {
      return {
        ...state,

        listingSaloonLoader: true,
        initialListingLoader: true,
        initialListingLoader: action.initialLoader,
        listingSaloonData: [],
        categorysaloonDetail: [],
      };
    }
    case actions.GET_LOGIN_LISTING_SALOON_SUCCESS: {
      return {
        ...state,
        listingSaloonLoader: false,
        initialListingLoader: false,
        listingSaloonData: action.payload,
        categorysaloonDetail: [],
      };
    }
    case actions.GET_LOGIN_LISTING_SALOON_FAILURE: {
      return {
        ...state,
        listingSaloonLoader: false,
        initialListingLoader: false,
        listingSaloonData: [],
        categorysaloonDetail: [],
      };
    }

    case actions.GET_NEARBY_SALOON: {
      return {
        ...state,

        listingSaloonLoader: true,
        initialListingLoader: true,
        initialListingLoader: action.initialLoader,
        listingSaloonData: [],
        categorysaloonDetail: [],
      };
    }
    case actions.GET_NEARBY_SALOON_SUCCESS: {
      return {
        ...state,
        listingSaloonLoader: false,
        initialListingLoader: false,
        listingSaloonData: action.payload[0],
        categorysaloonDetail: [],
      };
    }
    case actions.GET_NEARBY_SALOON_FAILURE: {
      return {
        ...state,
        listingSaloonLoader: false,
        initialListingLoader: false,
        listingSaloonData: [],
        categorysaloonDetail: [],
      };
    }

    case actions.GET_CATEGORY_SALOON_DETAILS_SUCCESS: {
      let _booklist = action.payload.filter((o) => {
        return o.hasOwnProperty("bookinglist");
      });
      let _vendorList = action.payload.filter((o) => {
        return o.hasOwnProperty("vendorlist");
      });
      let _timing = action.payload.filter((o) => {
        return o.hasOwnProperty("vendorhours");
      });
      let _images = action.payload.filter((o) => {
        return o.hasOwnProperty("vendorimages");
      });

      let _vendoravgrating = action.payload.filter((o) => {
        return o.hasOwnProperty("ratinglist");
      });

      _vendorList.map(async (l) => {
        var timings_ = [];
        var images_ = [];
        _timing.map((lt) => {
          var timings = {};
          if (lt.vendorid == l.id) {
            timings.id = lt.id;
            timings.days = lt.days;
            timings.starttime = lt.starttime;
            timings.endtime = lt.endtime;
            timings_.push(timings);
          }
        });
        _images.map((lt) => {
          var images = {};
          if (lt.vendorid == l.id) {
            images.id = lt.id;
            images.image_url = lt.image_url;
            images.status = lt.status;
            images.vendorid = lt.vendorid;
            images.vendorimagekey = lt.vendorimagekey;
            images.vendorimagepath = lt.vendorimagepath;
            images_.push(images);
          }
        });

        l.timings = timings_;
        l.images = images_;
      });
      console.log("vendor list ", _vendorList);
      return {
        ...state,
        listingSaloonLoader: false,
        initialListingLoader: false,
        categorysaloonDetail: _vendorList, //action.payload,
        listingSaloonData: [],
        bookingList: _booklist,
        vendorRatingAvg: _vendoravgrating,
      };
    }

    case actions.GET_CATEGORY_SALOON_DETAILS: {
      return {
        ...state,
        listingSaloonLoader: true,
        initialListingLoader: true,
        categorysaloonDetail: [],
        listingSaloonData: [],
      };
    }

    case actions.SET_SALOON_FAVOURITE_SUCCESS: {
      var wordsa = state.listingSaloonData;

      var categoryNew = state.categorysaloonDetail;
      const favOut = wordsa.filter((word) => word.id == action.payload);
      const catfavOut = categoryNew.filter(
        (catnew) => catnew.id == action.payload
      );
      wordsa.filter((currentValue, index, wordsa) => {
        if (currentValue.id == favOut[0]["id"]) {
          if (wordsa[index].favourite == 1) {
            wordsa[index].favourite = 0;
          } else {
            wordsa[index].favourite = 1;
          }
          return wordsa;
        }
      });

      if (categoryNew) {
        categoryNew.filter((catcurrentValue, index, wordsa) => {
          if (
            categoryNew.length > 0 &&
            catcurrentValue.id == catfavOut[0]["id"]
          ) {
            if (categoryNew[index].favourite == 1) {
              categoryNew[index].favourite = 0;
            } else {
              categoryNew[index].favourite = 1;
            }
            return categoryNew;
          }
        });
      }
      return {
        ...state,
        listingSaloonLoader: false,
        initialListingLoader: false,
        listingSaloonData: wordsa,
        categorysaloonDetail: categoryNew,
      };
    }

    /*
return {
          ...state,
          listingSaloonLoader: false,
          initialListingLoader: false,
          listingSaloonData: action.payload,
          categorysaloonDetail: []
        };
        */
    case actions.GET_VENDOR_BY_NAME: {
      return {
        ...state,
        initialListingLoader: true,
      };
    }
    case actions.GET_VENDOR_BY_NAME_SUCCESS: {
      return {
        ...state,
        initialListingLoader: false,
        vendorbynameData: action.payload,
      };
    }

    case actions.GET_VENDOR_BY_NAME_FAILURE: {
      return {
        ...state,
        initialListingLoader: false,
      };
    }

    case actions.SET_CURRENT_LATLONG: {
      return {
        ...state,
        latlongData: action.payload,
      };
    }

    case actions.GET_CURRENT_LATLONG: {
      return {
        ...state,
        latlongData: action.payload,
      };
    }

    case actions.GET_CURRENT_ADDRESS_STRING_SUCCESS: {
      return {
        ...state,
        formattedAddress: action.payload,
      };
    }
    case actions.GET_CURRENT_ADDRESS_STRING_FAILURE: {
      return {
        ...state,
        formattedAddress:"",
      };
    }
    default:
      return state;
  }
}
