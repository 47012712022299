const actions = {
    POST_CATEGORY: 'POST_CATEGORY',
    GET_CATEGORY_LIST: 'GET_CATEGORY_LIST',
    CATEGORY_LIST_RESPONSE: 'CATEGORY_LIST_RESPONSE',
    GET_CATEGORY: 'GET_CATEGORY',
    CATEGORY_RESPONSE: 'CATEGORY_RESPONSE',
    CALL_LOADER: 'CALL_LOADER',
    CALL_CATEGORY_SUCESS: 'CALL_CATEGORY_SUCESS',
    DELETE_CATEGORY: 'DELETE_CATEGORY',
    UPDATE_CATEGORY_OPEN: 'UPDATE_CATEGORY_OPEN',
    CALL_CATEGORY_UPDATE_SUCESS:'CALL_CATEGORY_UPDATE_SUCESS',
    STOP_LOOP : 'STOP_LOOP',

    POST_VENDOR_CATEGORY: 'POST_VENDOR_CATEGORY',
    GET_VENDOR_CATEGORY_LIST: 'GET_VENDOR_CATEGORY_LIST',
    VENDOR_CATEGORY_LIST_RESPONSE: 'VENDOR_CATEGORY_LIST_RESPONSE',
    GET_VENDOR_CATEGORY_FAIL: 'GET_VENDOR_CATEGORY_FAIL',
    VENDOR_CATEGORY_RESPONSE: 'VENDOR_CATEGORY_RESPONSE',
    DELETE_VENDOR_CATEGORY: 'DELETE_VENDOR_CATEGORY',

    GET_ADMIN_SERVICE_VENDOR_LIST: 'GET_ADMIN_SERVICE_HOTEL_LIST',
    GET_ADMIN_SERVICE_VENDOR_LIST_RESPONSE: 'GET_ADMIN_SERVICE_HOTEL_LIST_RESPONSE',
    GET_SERVICES_LIST: 'GET_SERVICES_LIST',
    GET_SERVICES_LIST_RESPONSE: 'GET_SERVICES_LIST_RESPONSE',
    POST_SERVICES: 'POST_SERVICES',
    CALL_SERVICE_SUCESS: 'CALL_SERVICE_SUCESS',
    DELETE_SERVICE: 'DELETE_SERVICE',

    GET_VENDOR_SERVICES_LIST: 'GET_VENDOR_SERVICES_LIST',
    GET_VENDOR_SERVICES_LIST_RESPONSE: 'GET_VENDOR_SERVICES_LIST_RESPONSE',
    POST_VENDOR_SERVICES_CAT: 'POST_VENDOR_SERVICES_CAT',
    DELETE_VENDOR_SERVICE: 'DELETE_VENDOR_SERVICE',

    GET_STAFF_LIST: 'GET_STAFF_LIST',
    GET_STAFF_LIST_RESPONSE: 'GET_STAFF_LIST_RESPONSE',
    GET_VENDOR_LIST: 'GET_VENDOR_LIST',
    GET_VENDOR_LIST_RESPONSE: 'GET_VENDOR_LIST_RESPONSE',

    GET_VENDORSTAFF_LIST : 'GET_VENDORSTAFF_LIST',
    GET_VENDORSTAFF_LIST_RESPONSE: 'GET_VENDORSTAFF_LIST_RESPONSE',


    GET_VENDOR_SERVICE_LISTDATA:'GET_VENDOR_SERVICE_LISTDATA',
    GET_VENDOR_SERVICE_LISTDATA_RES:'GET_VENDOR_SERVICE_LISTDATA_RES',
    GET_VENDOR_SERVICE_LISTDATA_REQ:'GET_VENDOR_SERVICE_LISTDATA_REQ'
}

export default actions;