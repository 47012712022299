const actions = {
  GET_SALOON_DETAILS: "GET_SALOON_DETAILS",
  GET_SALOON_DETAILS_SUCCESS: "GET_SALOON_DETAILS_SUCCESS",
  GET_SALOON_DETAILS_FAILURE: "GET_SALOON_DETAILS_FAILURE",
  GET_SALOON_SERVICES: "GET_SALOON_SERVICES",
  GET_SALOON_SERVICES_SUCCESS: "GET_SALOON_SERVICES_SUCCESS",
  GET_SALOON_SERVICES_FAILURE: "GET_SALOON_SERVICES_FAILURE",
  GET_SALOON_CATEGORY: "GET_SALOON_CATEGORY",
  GET_SALOON_CATEGORY_SUCCESS: "GET_SALOON_CATEGORY_SUCCESS",
  GET_SALOON_CATEGORY_FAILURE: "GET_SALOON_CATEGORY_FAILURE",
  SET_NEW_CATEGORY_SERVICE_DATA: "SET_NEW_CATEGORY_SERVICE_DATA",
  SET_NEW_CATEGORY_SERVICE_LOADER: "SET_NEW_CATEGORY_SERVICE_DATA_LOADER",
  SET_SID: "SET_SID",
  GET_SALOON_STAFF_DETAILS: "GET_SALOON_STAFF_DETAILS",
  GET_SALOON_STAFF_DETAILS_SUCCESS: "GET_SALOON_STAFF_DETAILS_SUCCESS",
  GET_SALOON_STAFF_DETAILS_FAILURE: "GET_SALOON_STAFF_DETAILS_FAILURE",
  GET_SERVICE_STAFF_DETAILS: "GET_SERVICE_STAFF_DETAILS",
  GET_SERVICE_STAFF_DETAILS_SUCCESS: "GET_SERVICE_STAFF_DETAILS_SUCCESS",
  GET_SERVICE_STAFF_DETAILS_FAILURE: "GET_SERVICE_STAFF_DETAILS_FAILURE",
  GET_CATEGORY_SALOON_DETAILS: "GET_CATEGORY_SALOON_DETAILS",
  SET_BOOKING: "SET_BOOKING",
  SET_SALOON_BOOKING_SUCCESS: "SET_SALOON_BOOKING_SUCCESS",
  SET_SALOON_BOOKING_FAILURE: "SET_SALOON_BOOKING_FAILURE",
  SHOW_MAP: "SHOW_MAP",
  GET_CMS_DETAILS: "GET_CMS_DETAILS",
  GET_CMS_DETAILS_RESPONSE: "GET_CMS_DETAILS_RESPONSE",
  GET_FAQ_DETAILS: "GET_FAQ_DETAILS",
  GET_FAQ_DETAILS_RES: "GET_FAQ_DETAILS_RES",
  GET_FAQ_DETAILS_COMMON: "GET_FAQ_DETAILS_COMMON",
  GET_FAQ_DETAILS_COMMON_RES: "GET_FAQ_DETAILS_COMMON_RES",
  GET_CMS_VIEWDETAILS: "GET_CMS_VIEWDETAILS",
  RATING_DATA: "RATING_DATA",
  RATING_DATA_COMMENT: "RATING_DATA_COMMENT",
  RATING_DATA_NAME: "RATING_DATA_NAME",
  RATING_DATA_MARK: "RATING_DATA_MARK",
  RATING_SUCCESS_RES: "RATING_SUCCESS_RES",
  RATING_FAILURE_RES: "RATING_FAILURE_RES",
  SAVE_RATING: "SAVE_RATING",
  RATING_RESET: "RATING_RESET",
  SET_DETAILSALOON_FAVOURITE: "SET_DETAILSALOON_FAVOURITE",
  VALIDATE_VOUCHER: "VALIDATE_VOUCHER",
  VALIDATE_VOUCHER_RESPONSE: "VALIDATE_VOUCHER_RESPONSE",
  GET_SALOON_FAVOURITE_DETAILS: "GET_SALOON_FAVOURITE_DETAILS",
  GET_BOOKING_DETAILS: "GET_BOOKING_DETAILS",
  GET_BOOKING_DETAILS_SUCCESS: "GET_BOOKING_DETAILS_SUCCESS",
  GET_BOOKING_DETAILS_FAILURE: "GET_BOOKING_DETAILS_FAILURE",
  USER_BOOKING_CANCEL: "USER_BOOKING_CANCEL",
  GET_SALOON_DETAILS_DATES: "GET_SALOON_DETAILS_DATES",
  GET_SALOON_DETAILS_DATES_SUCCESS: "GET_SALOON_DETAILS_DATES_SUCCESS",
  GET_SALOON_DETAILS_DATES_FAILURE: "GET_SALOON_DETAILS_DATES_FAILURE",

  GET_RATING_BY_VENDORID: "GET_RATING_BY_VENDORID",
  GET_RATING_BY_VENDORID_SUCCESS: "GET_RATING_BY_VENDORID_SUCCESS",
  GET_RATING_BY_VENDORID_FAILURE: "GET_RATING_BY_VENDORID_FAILURE",

  GET_SALON_STAFF_TIME_SLOT: "GET_SALON_STAFF_TIME_SLOT",
  GET_SALON_STAFF_TIME_SLOT_SUCCESS: "GET_SALON_STAFF_TIME_SLOT_SUCCESS",
  GET_SALON_STAFF_TIME_SLOT_FAILURE: "GET_SALON_STAFF_TIME_SLOT_FAILURE",
};

export default actions;
