import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BroadcastChannel } from 'broadcast-channel';
import {
  Layout,
  Button,
  Menu,
  Modal,
  Dropdown,
  Input,
  message,
  Select,
} from 'antd';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import actions from 'redux/auth/actions';
// import InjectMessage from "components/utility/intlMessages";
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import 'assets/css/header.scss';
import 'assets/css/modal.scss';
import 'assets/css/responsive.scss';
import { getLocalDataType, getLocalData } from 'redux/helper';
import { history, store } from 'redux/store';
import LoginPage from 'containers/Layouts/login';
import SignupPage from 'containers/Layouts/signup';
import ForgotModal from 'containers/Layouts/ForgotModal';
import ForgotPassword from 'containers/Layouts/ForgotPassword';
import ForgotPasswordConfirmed from 'containers/Layouts/ForgotPasswordConfirmed';
import addressActions from 'redux/admin/address/actions';
import { parsePhoneNumber } from 'react-phone-number-input';
import Countdown, { zeroPad } from 'react-countdown';
import { Form } from 'antd';

const Auth = (props) => {
  const { subLang, isLoggedIn, isOtp, isEmail, userSignupData } = useSelector(
    (state) => state.Auth
  );
  const { loading } = useSelector((state) => state.App);
  const dispatch = useDispatch();
  const { userCityList, userCountryList } = useSelector(
    (state) => state.Address
  );
  const [page, setPage] = useState('');
  const [isForgotVisible, setIsForgotVisible] = useState(false);
  const [visibleMFA, setVisibleMFA] = useState(false);
  const [isOtpVisible, setIsOtpVisible] = useState(isOtp);
  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [otptext, setotptext] = useState(true);
  const [OTPdata, setOTPdata] = useState('');
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const clockRef = useRef();
  const [form] = Form.useForm();
  const [formMFA] = Form.useForm();
  const handleStart = () => clockRef.current?.start();

  useEffect(() => {
    const windowUrl = props.location.search;
    const params = new URLSearchParams(windowUrl).get('type');
    setPage(params);
    store.dispatch({
      type: addressActions.GET_USER_COUNTRY_LIST,
    });
  }, []);
  useEffect(() => {
    const windowUrl = props.location.search;
    const params = new URLSearchParams(windowUrl).get('type');
    setPage(params);
  }, [location.search]);
  useEffect(() => {
    setIsOtpVisible(isOtp);
    setIsResendDisabled(true);
    handleStart();
  }, [isOtp]);
  useEffect(() => {
    setIsResendDisabled(true);
    handleStart();
  }, [isEmail]);

  // console.log("isOtp 1: " + isOtp);
  // console.log("isOtp 2: " + isOtpVisible);

  const fOk = () => {
    setIsForgotVisible(false);
  };

  const onFinish = (values) => {
    setLoad(true);
    let loginData = {
      email: values.email,
      password: values.password,
    };
    localStorage.setItem('loginData', JSON.stringify(loginData));

    var countryCode = '';
    if (
      values.phonenumber !== undefined &&
      typeof values.phonenumber !== undefined &&
      parsePhoneNumber(values.phonenumber) !== undefined &&
      typeof parsePhoneNumber(values.phonenumber) !== undefined
    ) {
      const countryCode1 = parsePhoneNumber(values.phonenumber);
      countryCode = countryCode1.countryCallingCode;
    }

    values['countrycode'] = countryCode;
    values['contactnumber'] = values.phonenumber;
    values['confirmpassword'] = values.password;
    values['cityid'] = 1;
    values['countryid'] = 1;
    values['usercountryname'] = values.usercountryname;
    setotptext(true);
    dispatch({
      type: actions.CREATE_AUTHENTICATE_USER,
      payload: values,
      callBackAction: () => {
        setLoad(false);
      },
    });
  };

  const onFinishMFA = (values) => {
    const user = JSON.parse(localStorage.getItem('content-user'));
    dispatch({
      type: actions.AUTHENTICATE_USER_MFA,
      payload: {
        ...values,
        email: user.email,
        contactnumber: user.contactnumber,
      },
    });
  };
  const onFinishLogin = (values) => {
    dispatch({
      type: actions.AUTHENTICATE_USER,
      payload: values,
      // callBackAction: (status) => {
      //   setVisibleMFA(!visibleMFA);
      // },
    });
    // props.history.push("/");
  };
  const otpOk = () => {
    Modal.confirm({
      title: 'Warning',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to cancel the otp verification?',
      okText: 'ok',
      cancelText: 'cancel',
      maskClosable: true,
      mask: true,
      onOk: () => {
        setIsOtpVisible(false);
        setIsResendDisabled(true);
        form.resetFields();
      },
    });
  };

  const otpOkMFA = () => {
    Modal.confirm({
      title: 'Warning',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to cancel the otp verification?',
      okText: 'ok',
      cancelText: 'cancel',
      maskClosable: true,
      mask: true,
      onOk: () => {
        setVisibleMFA(false);
        formMFA.resetFields();
      },
    });
  };

  const onFinishForgot = (values) => {
    dispatch({
      type: actions.SEND_PASSWORD_RESET_LINK,
      payload: values,
      callBackAction: () => {
        setPage('confirm');
        //Reset password email sent successfully. Please check your mail.

        LoginForgot({
          login: true,
          forgot: false,
        });
      },
    });
  };

  const LoginForgot = ({ login, forgot }) => {
    if (forgot) {
      setIsForgotVisible(forgot);
    } else if (login) {
      setIsForgotVisible(forgot);
    }
  };

  const onResendOTP = () => {
    setotptext(false);
    dispatch({
      type: actions.RESEND_OTP,
      payload: {
        contactnumber: userSignupData.contactnumber,
        countrycode: userSignupData.countrycode,
      },
      callBackAction: (status) => {
        isOtpVisible(true);
      },
    });
  };

  const onFinishOTP = (values) => {
    console.log('otp_data:', values.otp);

    dispatch({
      type: actions.VERIFY_OTP,
      payload: {
        contactnumber: userSignupData.contactnumber,
        otp: values.otp,
      },
      callBackAction: (status) => {
        setTimeout(() => {
          props.history.push('/auth?type=login');
        }, 1000);
      },
    });
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setIsResendDisabled(false);
      return '00:00';
    } else {
      // Render a countdown
      return (
        <span>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  const handleEmailModalCancel = () => {
    setIsEmailVisible(false);
    dispatch({
      type: actions.VERIFY_EMAIL_MODAL_CANCEL,
    });
    props.history.push('/auth?type=login');
  };

  const resendVerificationEmail = () => {
    dispatch({
      type: actions.RESEND_EMAIL_VERIFICATION,
      payload: {
        email: userSignupData.email,
      },
      callBackAction: (status) => {
        setIsEmailVisible(true);
      },
    });
  };
  return (
    <div>
      {(() => {
        if (page == 'login') {
          return (
            <LoginPage
              onLoginForgot={LoginForgot}
              onFinishLogin={onFinishLogin}
              loader={loading}
            />
          );
        } else if (page == 'signup') {
          return (
            <SignupPage
              userCountryList={userCountryList}
              userCityList={userCityList}
              loading={loading}
              onFinish={onFinish}
              load={load}
            />
          );
        } else if (page == 'forgot') {
          return (
            <ForgotPassword
              onFinish={onFinishForgot}
              onLoginForgot={LoginForgot}
              loader={loading}
            />
          );
        } else {
          return <ForgotPasswordConfirmed type={'auth'} />;
        }
      })()}

      <ForgotModal
        visible={isForgotVisible}
        onCancel={fOk}
        onFinish={onFinishForgot}
        onLoginForgot={LoginForgot}
        loader={loading}
      />

      <Modal
        title={false}
        visible={isOtpVisible}
        onCancel={otpOk}
        centered
        footer={false}
        className='modal-ui-1 modal-otp'
        width='100%'
        destroyOnClose
      >
        <div className='modal-body-ui'>
          <h2>OTP Verification</h2>
          <p className='sub'>
            Enter the OTP you received to <br />{' '}
            {userSignupData
              ? userSignupData.contactnumber?.replace(/\d(?=\d{4})/g, '*')
              : ''}
          </p>

          <Form form={form} name='control-ref' onFinish={onFinishOTP}>
            <Form.Item
              style={{ width: '100%' }}
              name='otp'
              rules={[
                {
                  required: true,
                  message: 'Please input OTP',
                },
              ]}
            >
              <Input placeholder='please enter valid OTP' />
            </Form.Item>
            <div className='ant-form-item'>
              <Button
                type='primary'
                htmlType='submit'
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form>
          {/**<OTP OTPdata={OTPdata} onChangeOTP={onChangeOTP} /> */}

          <p style={{ textAlign: 'center' }}>
            {otptext && (
              <>
                Please wait for&nbsp;
                <Countdown
                  ref={clockRef}
                  autoStart={true}
                  date={Date.now() + 60 * 1000}
                  renderer={renderer}
                />{' '}
                to resend the OTP
              </>
            )}
            <Button
              className='resend_otp'
              disabled={isResendDisabled}
              loading={loading}
              type='link'
              onClick={onResendOTP}
            >
              Resend OTP
            </Button>
          </p>
        </div>
      </Modal>

      <Modal
        title={false}
        visible={isEmailVisible}
        onCancel={handleEmailModalCancel}
        centered
        footer={false}
        className='modal-ui-1 modal-otp'
        width='100%'
        destroyOnClose
      >
        <div className='modal-body-ui'>
          <h2>Email Verification</h2>
          <p className='sub'>
            Please verify your email to proceed further <br />{' '}
            {userSignupData ? userSignupData.email : ''}
          </p>

          <p style={{ textAlign: 'center' }}>
            {otptext && (
              <>
                Please wait for&nbsp;
                <Countdown
                  ref={clockRef}
                  autoStart={true}
                  date={Date.now() + 30 * 1000}
                  renderer={renderer}
                />{' '}
                to resend the Email Verification Link
              </>
            )}
            <Button
              className='resend_otp'
              disabled={isResendDisabled}
              loading={loading}
              type='link'
              onClick={resendVerificationEmail}
            >
              Resend Email
            </Button>
          </p>
        </div>
      </Modal>

      <Modal
        title={false}
        visible={visibleMFA}
        onCancel={otpOkMFA}
        centered
        footer={false}
        className='modal-ui-1 modal-otp'
        width='100%'
        destroyOnClose
      >
        <div className='modal-body-ui'>
          <h2>Multi Factor Authendication</h2>
          <p className='sub'>
            Enter the OTP you received to <br />{' '}
            {userSignupData
              ? userSignupData.contactnumber?.replace(/\d(?=\d{4})/g, '*')
              : ''}
          </p>

          <Form form={formMFA} name='control-ref' onFinish={onFinishMFA}>
            <Form.Item
              style={{ width: '100%' }}
              name='otp'
              rules={[
                {
                  required: true,
                  message: 'Please input OTP',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <div className='ant-form-item'>
              <Button
                type='primary'
                htmlType='submit'
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form>
          {/**<OTP OTPdata={OTPdata} onChangeOTP={onChangeOTP} /> */}

          <p style={{ textAlign: 'center' }}>
            {otptext && (
              <>
                Please wait for&nbsp;
                <Countdown
                  ref={clockRef}
                  autoStart={true}
                  date={Date.now() + 60 * 1000}
                  renderer={renderer}
                />{' '}
                to resend the OTP
              </>
            )}
            <Button
              className='resend_otp'
              disabled={isResendDisabled}
              loading={loading}
              type='link'
              onClick={onResendOTP}
            >
              Resend OTP
            </Button>
          </p>
        </div>
      </Modal>
    </div>
  );
};
export const logoutChannel = new BroadcastChannel('user');
const logout = () => {
  localStorage.clear();
};
export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    console.log('testing_istiWorkign_logout');
    logout();
    logoutChannel.close();
  };
};
export default Auth;
