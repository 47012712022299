const actions = {
    POST_VOUCHER: 'POST_VOUCHER',
    GET_ADMIN_VOUCHER_LIST: 'GET_ADMIN_VOUCHER_LIST',
    VOUCHER_LIST_RESPONSE: 'VOUCHER_LIST_RESPONSE',
    GET_VOUCHER: 'GET_VOUCHER',
    VOUCHER_RESPONSE: 'VOUCHER_RESPONSE',
    CALL_LOADER: 'CALL_LOADER',
    DELETE_VOUCHER: 'DELETE_VOUCHER',
    GET_ALL_USER: 'GET_ALL_USER',
    GET_ALL_USER_RESPONSE: 'GET_ALL_USER_RESPONSE',

    POST_VENDOR_VOUCHER: 'POST_VENDOR_VOUCHER',
    DELETE_VENDOR_VOUCHER: 'DELETE_VENDOR_VOUCHER',
    GET_VENDOR_VOUCHER_CODE:'GET_VENDOR_VOUCHER_CODE',
    GET_VENDOR_VOUCHER_CODE_SUCCESS:'GET_VENDOR_VOUCHER_CODE_SUCCESS',
    GET_VENDOR_VOUCHER_CODE_FAILURE:'GET_VENDOR_VOUCHER_CODE_FAILURE',
    GET_VENDOR_VOUCHER_APP_TYPE:'GET_VENDOR_VOUCHER_APP_TYPE',
    GET_VENDOR_VOUCHER_APP_TYPE_SUCCESS:'GET_VENDOR_VOUCHER_APP_TYPE_SUCCESS',
    GET_VENDOR_VOUCHER_APP_TYPE_FAILURE:'GET_VENDOR_VOUCHER_APP_TYPE_FAILURE',
    GET_VENDOR_VOUCHER_TYPE:'GET_VENDOR_VOUCHER_TYPE',
    GET_VENDOR_VOUCHER_TYPE_SUCCESS:'GET_VENDOR_VOUCHER_TYPE_SUCCESS',
    GET_VENDOR_VOUCHER_TYPE_FAILURE:'GET_VENDOR_VOUCHER_TYPE_FAILURE',
    GET_VENDOR_VOUCHER_LANGUAGE_LIST: 'GET_VENDOR_VOUCHER_LANGUAGE_LIST',
    GET_VENDOR_VOUCHER_LANGUAGE_LIST_SUCCESS: 'GET_VENDOR_VOUCHER_LANGUAGE_LIST_SUCCESS',
    GET_VENDOR_VOUCHER_LANGUAGE_LIST_FAILURE: 'GET_VENDOR_VOUCHER_LANGUAGE_LIST_FAILURE',
    GET_VENDOR_VOUCHER_LIST: 'GET_VENDOR_VOUCHER_LIST',
    GET_VENDOR_VOUCHER_LIST_RESPONSE: 'GET_VENDOR_VOUCHER_LIST_RESPONSE',
}

export default actions;