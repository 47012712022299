import { all, put, call, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { getRequest, postRequest } from 'helpers/axiosClient';
import { message } from 'antd';
import axios from 'axios';
import moment from 'moment';

export function* loginListingSaloon(params) {
  try {
    const {
      latitude,
      longitude,
      sortby,
      service_available,
      userid,
      vendoraddress,
      categoryid,
      vendorlocation,
      searchdate,
      searchtime,
      vendorid,
    } = params.payload;

    var url = `public/website/getvendors?status=1&latitude=${
      latitude ? latitude : 23.8859
    }&longitude=${longitude ? longitude : 45.0792}`;

    if (userid) {
      url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${longitude ? longitude : 45.0792}&userid=${userid}`;
    }
    if (userid && sortby !== undefined && sortby !== null && sortby !== '') {
      url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&userid=${userid}&sortby=${sortby}`;
    }
    if (sortby !== undefined && sortby !== null && sortby !== '') {
      url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${longitude ? longitude : 45.0792}&sortby=${sortby}`;
    }

    if (
      service_available !== undefined &&
      service_available !== null &&
      service_available !== ''
    ) {
      url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&service_available=${service_available}`;
    }

    if (
      sortby &&
      sortby !== undefined &&
      sortby !== null &&
      sortby !== '' &&
      service_available !== undefined &&
      service_available !== null &&
      service_available !== '' &&
      vendorlocation
    ) {
      url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&sortby=${sortby}&service_available=${service_available}&vendorlocation=${
        vendorlocation !== '' ||
        vendorlocation !== null ||
        vendorlocation !== undefined
          ? vendorlocation
          : ''
      }`;
    }

    if (
      userid &&
      sortby !== undefined &&
      sortby !== null &&
      sortby !== '' &&
      service_available !== undefined &&
      service_available !== null &&
      service_available !== '' &&
      vendorlocation
    ) {
      url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&sortby=${sortby}&service_available=${service_available}&userid=${userid}&vendorlocation=${
        vendorlocation !== '' ||
        vendorlocation !== null ||
        vendorlocation !== undefined
          ? vendorlocation
          : ''
      }`;
    }

    if (vendoraddress) {
      var url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&vendoraddress=${vendoraddress}`;
    }

    if (vendoraddress && userid) {
      var url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&vendoraddress=${vendoraddress}&userid=${userid}`;
    }

    if (
      vendoraddress &&
      userid &&
      sortby !== undefined &&
      sortby !== null &&
      sortby !== ''
    ) {
      var url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&vendoraddress=${vendoraddress}&userid=${userid}&sortby=${sortby}`;
    }

    if (
      vendoraddress &&
      sortby !== undefined &&
      sortby !== null &&
      sortby !== '' &&
      service_available !== undefined &&
      service_available !== null &&
      service_available !== '' &&
      userid
    ) {
      var url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&vendoraddress=${vendoraddress}&userid=${userid}&sortby=${sortby}&service_available=${service_available}`;
    }
    if (categoryid !== undefined && categoryid !== '') {
      var url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${longitude ? longitude : 45.0792}&categoryid=${categoryid}`;
    }

    if (
      categoryid !== undefined &&
      userid !== undefined &&
      categoryid !== '' &&
      userid !== ''
    ) {
      var url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&categoryid=${categoryid}&userid=${userid}`;
    }

    if (
      categoryid !== undefined &&
      userid !== undefined &&
      sortby !== undefined &&
      sortby !== null &&
      sortby !== '' &&
      categoryid !== '' &&
      userid !== ''
    ) {
      var url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&categoryid=${categoryid}&userid=${userid}&sortby=${sortby}`;
    }

    if (
      categoryid !== undefined &&
      sortby !== undefined &&
      sortby !== null &&
      sortby !== '' &&
      service_available !== undefined &&
      service_available !== null &&
      service_available !== '' &&
      userid !== undefined &&
      categoryid !== '' &&
      userid !== ''
    ) {
      var url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&categoryid=${categoryid}&userid=${userid}&sortby=${sortby}&service_available=${service_available}`;
    }

    if (
      categoryid !== undefined &&
      sortby !== undefined &&
      sortby !== null &&
      sortby !== '' &&
      service_available !== undefined &&
      service_available !== null &&
      service_available !== '' &&
      vendoraddress &&
      userid !== undefined &&
      categoryid !== '' &&
      userid !== ''
    ) {
      var url = `public/website/getvendors?status=1&latitude=${
        latitude ? latitude : 23.8859
      }&longitude=${
        longitude ? longitude : 45.0792
      }&categoryid=${categoryid}&userid=${userid}&sortby=${sortby}&service_available=${service_available}&vendoraddress=${vendoraddress}`;
    }

    if (searchdate) {
      var url =
        url +
        `&fromdate=${searchdate}&todate=${moment(searchdate)
          .add(1, 'd')
          .format('YYYY-MM-DD')} ${searchtime}`;
    }

    if (searchdate && searchtime) {
      if (url.search(`fromdate`) > 0 && url.search(`todate`)) {
        var url = url;
      } else {
        var url =
          url +
          `&fromdate=${searchdate} ${searchtime}&todate=${moment(searchdate)
            .add(1, 'd')
            .format('YYYY-MM-DD')} ${searchtime}`;
      }
    }

    if (vendorid) {
      var url = url + `&vendorid=${vendorid}`;
    }

    const response = yield call(() => getRequest(url));
    if (response) {
      params.callBackAction !== undefined && params.callBackAction(response);
    }
    yield put({
      type: actions.GET_LISTING_SALOON_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    params.callBackAction !== undefined && params.callBackAction(error);

    yield put({ type: actions.GET_LOGIN_LISTING_SALOON_FAILURE });
  }
}

export function* listingSaloonData(params) {
  try {
    const { latitude, longitude, sortby, service_available } = params.payload;
    var url = `public/website/near_vendor?status=1&latitude=${latitude}&longitude=${longitude}`;
    if (sortby) {
      url = `public/website/near_vendor?status=1&latitude=${latitude}&longitude=${longitude}&sortby=${sortby}`;
    }
    if (service_available) {
      url = `public/website/near_vendor?status=1&latitude=${latitude}&longitude=${longitude}&service_available=${service_available}`;
    }
    if (sortby && service_available) {
      url = `public/website/near_vendor?status=1&latitude=${latitude}&longitude=${longitude}&sortby=${sortby}&service_available=${service_available}`;
    }

    const response = yield call(() => getRequest(url));

    yield put({
      type: actions.GET_NEARBY_SALOON_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_NEARBY_SALOON_FAILURE });
  }
}

export function* saloonFavourite(params) {
  try {
    const response = yield call(() =>
      postRequest('user/user/favvendor', { ...params.payload })
    );

    yield put({
      type: actions.SET_SALOON_FAVOURITE_SUCCESS,
      payload: params.payload.vendorid,
    });
    message.success(
      `Your Saloon is ${params.payload.favourite ? 'Added' : 'Removed'}`
    );
    if (response) {
      params.callBackAction(response);
    }
  } catch (error) {
    yield put({ type: actions.SET_SALOON_FAVOURITE_FAILURE });
  }
}

export function* categorysaloonDetail(params) {
  try {
    var URL = '';
    if (params.payload) {
      URL = `public/website/category_saloons?status=1&categoryid=${params.payload}`;
    } else {
      URL = `public/website/category_saloons?status=1`;
    }
    const response = yield call(() => getRequest(URL));
    if (response) {
      params.callBackAction(response);
    }
    yield put({
      type: actions.GET_CATEGORY_SALOON_DETAILS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_CATEGORY_SALOON_DETAILS_FAILURE });
  }
}

export function* vendorbyname(params) {
  try {
    var URL = `public/website/getvendorbyname?vendorname=${params.payload}`;
    const response = yield call(() => getRequest(URL));
    yield put({
      type: actions.GET_VENDOR_BY_NAME_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.GET_VENDOR_BY_NAME_FAILURE });
  }
}
export function* getLocationcity(params) {
  const { lat, lng, key } = params.payload;
  try {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=${key}`
      )
      .then((response) => {
        if (response) {
          params.callBackAction(response);
        }
      })
      .catch((err) => message.error(err));
  } catch (error) {
    yield put({ type: actions.GET_CURRENT_ADDRESS_STRING_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_NEARBY_SALOON, listingSaloonData),
    takeEvery(actions.GET_LOGIN_LISTING_SALOON, loginListingSaloon),
    takeEvery(actions.SET_SALOON_FAVOURITE, saloonFavourite),
    takeEvery(actions.GET_CATEGORY_SALOON_DETAILS, categorysaloonDetail),
    takeEvery(actions.GET_VENDOR_BY_NAME, vendorbyname),
    takeEvery(actions.GET_CURRENT_ADDRESS_STRING, getLocationcity),
  ]);
}
