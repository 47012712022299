const actions = {
  GET_LISTING_SALOON: "GET_LISTING_SALOON",
  GET_LISTING_SALOON_SUCCESS: "GET_LISTING_SALOON_SUCCESS",
  GET_LISTING_SALOON_FAILURE: "GET_LISTING_SALOON_FAILURE",
  GET_LOGIN_LISTING_SALOON: "GET_LOGIN_LISTING_SALOON",
  GET_LOGIN_LISTING_SALOON_SUCCESS: "GET_LOGIN_LISTING_SALOON_SUCCESS",
  GET_LOGIN_LISTING_SALOON_FAILURE: "GET_LOGIN_LISTING_SALOON_FAILURE",
  SET_SALOON_FAVOURITE: "SET_SALOON_FAVOURITE",
  SET_SALOON_FAVOURITE_SUCCESS: "SET_SALOON_FAVOURITE_SUCCESS",
  SET_SALOON_FAVOURITE_FAILURE: "SET_SALOON_FAVOURITE_FAILURE",
  GET_CATEGORY_SALOON_DETAILS_SUCCESS: "GET_CATEGORY_SALOON_DETAILS_SUCCESS",
  GET_CATEGORY_SALOON_DETAILS_FAILURE: "GET_CATEGORY_SALOON_DETAILS_FAILURE",
  GET_CATEGORY_SALOON_DETAILS: "GET_CATEGORY_SALOON_DETAILS",
  GET_NEARBY_SALOON: "GET_NEARBY_SALOON",
  GET_NEARBY_SALOON_SUCCESS: "GET_NEARBY_SALOON_SUCCESS",
  GET_NEARBY_SALOON_FAILURE: "GET_NEARBY_SALOON_FAILURE",
  GET_VENDOR_BY_NAME: "GET_VENDOR_BY_NAME",
  GET_VENDOR_BY_NAME_SUCCESS: "GET_VENDOR_BY_NAME_SUCCESS",
  GET_VENDOR_BY_NAME_FAILURE: "GET_VENDOR_BY_NAME_FAILURE",
  SET_CURRENT_LATLONG: "SET_CURRENT_LATLANG",
  GET_CURRENT_LATLONG: "GET_CURRENT_LATLANG",
  GET_CURRENT_ADDRESS_STRING:"GET_CURRENT_ADDRESS_STRING",
  GET_CURRENT_ADDRESS_STRING_SUCCESS:"GET_CURRENT_ADDRESS_STRING_SUCCESS",
  GET_CURRENT_ADDRESS_STRING_FAILURE:"GET_CURRENT_ADDRESS_STRING_FAILURE"
};

export default actions;
